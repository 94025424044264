import React from 'react';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from "i18next";

const EmailLayoutsField = ({ emailLayouts, single, name }) => {

    const default_lang = Utils.default_lang();

    const options = (emailLayouts || []).map((emailLayout) => ({
        key: emailLayout._id,
        text: emailLayout.name[i18n.language] ?? emailLayout.name[default_lang],
        value: emailLayout._id
    }));

    return single ? (
        <SelectField name={ name } options={ options } clearable />
    ) : (
        <MultiSelectField name={ name } options={ options } />
    );
}

export default EmailLayoutsField;
