import BaseModel from './BaseModel';
import Ref from './Ref';
import Tag from './Tag';
import _ from 'lodash';

/** Class representing a pricing. */
class Pricing extends BaseModel {
    constructor(properties) {
        super(properties);

        if ('VAT' in this) {
            this.VAT = parseFloat(this.VAT);
        }

        if ('refs' in this)
            this.refs = (this.refs || []).map(ref => new Ref(ref));

        this.tags = this.tags || [];
        this.tags = (this.tags || []).map(tag => new Tag(tag));
    }
    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();

        _.mapKeys(prepared.price, (price, currency) => {
            prepared.price[currency] = Number(price);
        });

        _.mapKeys(prepared.value, (value, currency) => {
            prepared.value[currency] = Number(value);
        });

        if (!('value_per_screening' in prepared)) {
            prepared.value_per_screening = _.cloneDeep(prepared.value);
            _.mapKeys(prepared.value_per_screening, (value_per_screening, currency) => {
                prepared.value_per_screening[currency] = 0;
            });
        } else {
            _.mapKeys(prepared.value_per_screening, (value_per_screening, currency) => {
                prepared.value_per_screening[currency] = Number(value_per_screening);
            });
        }

        if (!('wallet_amount' in prepared)) {
            prepared.wallet_amount = _.cloneDeep(prepared.value);
            _.mapKeys(prepared.wallet_amount, (wallet_amount, currency) => {
                prepared.wallet_amount[currency] = 0;
            });
        } else {
            _.mapKeys(prepared.wallet_amount, (wallet_amount, currency) => {
                prepared.wallet_amount[currency] = Number(Math.max(0, wallet_amount));
            });
        }

        if (_.values(prepared.description).length === 0)
            delete prepared.description;

        prepared.opaque.eshop_sort_weight = parseInt(prepared.opaque?.eshop_sort_weight || 0, 10);

        prepared.VAT = Math.min(Math.max(0, parseFloat(this.VAT)), 100);

        if (!prepared.rules)
            return prepared;

        if (_.values(prepared.rules).length === 0) {
            prepared.rules = {};
            return prepared;
        }

        if (_.isEmpty(prepared.rules.only_for_roles))
            delete prepared.rules.only_for_roles;

        if (_.isEmpty(prepared.rules.only_for_tickettypes))
            delete prepared.rules.only_for_tickettypes;

        if (_.isEmpty(prepared.rules.exclude_tickettypes))
            delete prepared.rules.exclude_tickettypes;

        const not_before = prepared.rules.not_before;
        if (!not_before || _.isNull(not_before) || not_before === "")
            delete prepared.rules.not_before;

        const not_after = prepared.rules.not_after;
        if (!not_after || _.isNull(not_after) || not_after === "")
            delete prepared.rules.not_after;

        return prepared;
    }
}

export default Pricing;
