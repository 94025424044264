import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Row, Col, FormGroup, Label, Card, CardBody, CardTitle, CardText } from 'reactstrap';

import { DateTimePicker, PlacesField, TickettypesField, ToggleField } from '../../';

import logo from '../../../assets/images/integrations/own_it.png';

const OwnItFields = ({ tickettypes, places }) => {
    const { t }                  = useTranslation();
    const { isMobile, isTablet } = useSelector(state => state.ui);

    return (
        <div className="OwnItFields">
            <Card>
                <CardBody>
                    <CardTitle className="small">
                        <img src={logo} className="img rounded" />
                        { t('settings.integrations.own_it.title') }
                    </CardTitle>
                    <CardText tag="div">
                        <FormGroup row>
                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.own_it.api_key")}</Label>
                            <Col lg={10} >
                                <Field required component="input" className="form-control" type="text" name="integrations.own_it.api_key" id="api_key" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.own_it.places_ids")}</Label>
                            <Col lg={10} >
                                <PlacesField name="integrations.own_it.places_ids" places={ places } />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""}>{t("settings.integrations.own_it.test")}</Label>
                            <Col lg={10} >
                                <ToggleField name="integrations.own_it.test" />
                            </Col>
                        </FormGroup>
                    </CardText>
                </CardBody>
                <CardBody>
                    <CardTitle className="small">
                        { t('settings.integrations.own_it.codes_creation.title') }
                    </CardTitle>
                    <CardText tag="div">
                        <FormGroup row>
                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.own_it.codes_creation.product_id")}</Label>
                            <Col lg={10} >
                                <Field required component="input" className="form-control" type="text" name="integrations.own_it.codes_creation.product_id" id="product_id" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.own_it.codes_creation.tickettypes")}</Label>
                            <Col lg={10} >
                                <TickettypesField name="integrations.own_it.codes_creation.tickettypes" tickettypes={ tickettypes } />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.own_it.codes_creation.valid_from")}</Label>
                            <Col lg={10} >
                                <Field required component={DateTimePicker} className="form-control" type="text" name="integrations.own_it.codes_creation.valid_from" id="valid_from" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.own_it.codes_creation.valid_until")}</Label>
                            <Col lg={10} >
                                <Field required component={DateTimePicker} className="form-control" type="text" name="integrations.own_it.codes_creation.valid_until" id="valid_until" />
                            </Col>
                        </FormGroup>
                    </CardText>
                </CardBody>
            </Card>
        </div>
    );
}

export default OwnItFields;
