import BaseModel from './BaseModel';
import { v4 } from 'uuid';
import { EmailLayoutWidget } from './EmailLayoutWidgets';
import { Utils, Screen } from '../services';
import {
    CustomTextWidget,
    ImageWidget,
    LibraryImageWidget
} from './EmailLayoutWidgets';
import moment from "moment";
import _ from 'lodash';

/** Class representing a PDF layout. */
class EmailLayout extends BaseModel {
    /* Widget types */
    static TEXT          = 'text';
    static IMAGE         = 'image';
    static CUSTOM        = 'custom';
    static LIBRARY_IMAGE = 'library';

    /* Options types */
    static OPTION_COLOR             = 'color';
    static OPTION_LINKS_COLOR       = 'linksColor';
    static OPTION_BACKGROUND_COLOR  = 'background-color';
    static OPTION_TEXT              = 'text';
    static OPTION_TEXT_ALIGNMENT    = 'text-alignment';
    static OPTION_TEXT_DECORATION   = 'text-decoration';
    static OPTION_FONT_FAMILY       = 'font-family';
    static OPTION_FONT_SIZE         = 'font-size';
    static OPTION_LINE_HEIGHT       = 'line-height';
    static OPTION_ALIGNMENT         = 'alignment';
    static OPTION_PADDING           = 'padding';
    static OPTION_OBJECT_FIT        = 'object-fit';
    static OPTION_DATE_FORMAT       = 'date-format';
    static OPTION_IMAGE             = 'image';
    static OPTION_IMAGE_TYPE        = 'image-type';
    static OPTION_BORDER_STYLE      = 'border-style';
    static OPTION_BORDER_WIDTH      = 'border-width';
    static OPTION_BORDER_COLOR      = 'border-color';
    static OPTION_BORDER_RADIUS     = 'border-radius';
    static OPTION_LINK              = 'link';
    static OPTION_TEXT_TRANSFORM    = 'text-transform';

    static imageTypes = () => {
        return Utils.image_types('setting');
    };

    static objectFitValues = () => {
        return [
            'none',
            /*'fill', fill is not supported by PDFKit */
            'cover',
            'contain'
        ];
    };

    static borderStyleValues = () => {
        return [
            'solid',
            'dashed',
            /*'dotted', dotted is not supported by PDFKit */
        ];
    };

    static textDecorationValues = () => {
        return [
            'none',
            'underline',
            'line-through',
        ];
    };

    static textTransformValues = () => {
        return [
            'none',
            'capitalize',
            'uppercase',
            'lowercase'
        ];
    };

    static dateFormats = () => [
        "LT","LTS","L","l","LL",
        "ll","LLL","lll","LLLL","llll",
        "dddd DD.MM", "dddd DD.MM.YYYY",
        "dddd Do MMM", "dddd Do MMMM",
        "dddd Do MMM YYYY", "dddd Do MMMM YYYY",
        "ddd DD.MM", "ddd DD.MM.YYYY",
        "ddd Do MMM", "ddd Do MMMM",
        "ddd Do MMM YYYY", "ddd Do MMMM YYYY",
        "midnight_hack",
    ];

    static createWidget(properties) {
        switch (properties.type) {
            case EmailLayout.IMAGE:
                switch (properties.subType) {
                    case EmailLayout.CUSTOM:
                        return new ImageWidget(properties);
                    case EmailLayout.LIBRARY_IMAGE:
                        return new LibraryImageWidget(properties);
                }
                break;
            case EmailLayout.TEXT:
                switch (properties.subType) {
                    case EmailLayout.CUSTOM:
                        return new CustomTextWidget(properties);
                }
                break;
        }

        return null;
    }

    static getAvailableWidgets(type) {
        const availableWidgets = {
            [EmailLayout.IMAGE]: [
                new LibraryImageWidget(),
                new ImageWidget(),
            ],
            [EmailLayout.TEXT]: [
                new CustomTextWidget(),
            ]
        };

        if (type)
            return availableWidgets[type];

        return availableWidgets;
    }

    static defaultLayout() {
        const logoWidget = new LibraryImageWidget({ }).toPlainObject();
        logoWidget.options.type = 'logo';

        const contentWidget = new CustomTextWidget({ }).toPlainObject();
        Object.keys(contentWidget.options.text).forEach(lang => {
            contentWidget.options.text[lang] = '<p style="text-align: center">...<br />...<br />...<br /></p>';
        });

        const addressWidget = new CustomTextWidget({ }).toPlainObject();
        Object.keys(addressWidget.options.text).forEach(lang => {
            addressWidget.options.text[lang] = '<p style="text-align: center">';
            addressWidget.options.text[lang] += `   [customer.name.${lang}]<br/>`;
            addressWidget.options.text[lang] += `   [customer.address.${lang}]<br/>`;
            addressWidget.options.text[lang] += `   Tél. [customer.phone.${lang}]<br/>`;
            addressWidget.options.text[lang] += `   [customer.email.mail.${lang}]<br/>`;
            addressWidget.options.text[lang] += '</p>';
        });

        return {
            _id: v4(),
            layout_type: 'email',
            name: {
                fr: 'Modèle d\'email',
                en: 'Email Layout',
                de: 'Email Layout'
            },
            widgets: [
                logoWidget,
                contentWidget,
                addressWidget
            ]
        };
    }

    constructor(properties) {
        super(properties);

        this.widgets = (this.widgets || []).map(w => new EmailLayoutWidget(w));
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);

        if (prepared.options) {
            if (!prepared.options.bcc?.length)
                delete prepared.options.bcc;
            if (!prepared.options.replyTo?.length)
                delete prepared.options.replyTo;
        }
        return prepared;
    }

    /**
     * Prepare this object for duplicate.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForDuplicate() {
        const prepared = _.cloneDeep(this);
        prepared._id = v4();

        return prepared;
    }

    /**
     * Check if a lyout has any shortcode of a specific type
     *
     * @param {String} type
     * @param {String} lang
     * @return {Boolean}
     */
    hasShortcodeOfType(type, lang) {
        let result = false;

        const regexp = new RegExp(`\\[${type}[\.\\]]{1}`, 'g');
        this.widgets.forEach(widget => {
            const text = widget.options?.text ? widget.options?.text[lang] : '';
            result = result || regexp.test(text);
        });

        return result;
    }

    /**
     * Convert this widget instance to a plain object
     */
    toPlainObject = () => JSON.parse(JSON.stringify(this));
}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
EmailLayout.getListingFields = () => ['_id', 'name', 'layout_type'];

export default EmailLayout;
