import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { PageTitle } from '../../../components';
import PlaceForm from '../PlaceForm/PlaceForm';
import { startCreatePlace, createPlace } from '../../../redux';

const PlaceCreate = props => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { placeSuccessfullyCreated, place } = useSelector(state => state.places);

    useEffect(() => {
        dispatch(startCreatePlace());
    }, []);

    const submit = values => {
        dispatch(createPlace(values));
    };

    const title = <span>{t('places.add')}</span>;

    const actions =
        <Button tag={Link} to="/places" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="PlaceCreate">
            <PageTitle icon="th" title={ title } actions={ actions } />

            {placeSuccessfullyCreated &&
                <Redirect to={`/places/edit/${place._id}`} />
            }

            <PlaceForm onSubmit={ submit } isCreation="true" />
        </div>
    );
}

export default PlaceCreate;
