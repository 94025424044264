import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Input, InputGroup, InputGroupAddon, FormGroup, Label } from 'reactstrap';
import { Field } from 'redux-form'
import { changeSetting } from '../../redux/modules/settings';
import _ from 'lodash';

import './IntegrationsWebsitesField.css';

const IntegrationsWebsitesField = ({ name }) => {
    const [newWebsite, setNewWebsite] = useState('');

    const { t }                  = useTranslation();
    const dispatch               = useDispatch();
    const { isMobile, isTablet } = useSelector(state => state.ui);
    const { setting }            = useSelector(state => state.settings);

    const addWebsite = () => {
        const newSetting                    = _.cloneDeep(setting);
        newSetting.integrations             = newSetting.integrations ?? {};
        newSetting.integrations.wp          = newSetting.integrations.wp ?? {};
        newSetting.integrations.wp.websites = newSetting.integrations.wp.websites ?? [];

        newSetting.integrations.wp.websites.push({ name: newWebsite, rate: 1 });
        dispatch(changeSetting(newSetting));
        setNewWebsite('');
    }

    const removeWebsite = index => {
        const newSetting = _.cloneDeep(setting);
        newSetting.integrations.wp.websites = newSetting.integrations.wp.websites || [];
        newSetting.integrations.wp.websites.splice(index, 1);
        dispatch(changeSetting(newSetting));
    }

    return (
        <div className="IntegrationsWebsitesField">
            <Row>
                <Col sm={ 6 }>
                    <InputGroup>
                        <Input type="text" placeholder={ t('settings.integrations.wp.new_website_placeholder') } value={newWebsite} onChange={e => setNewWebsite(e.target.value)} />
                        <InputGroupAddon addonType="append">
                            <Button color="info" onClick={ addWebsite } disabled={!newWebsite || (newWebsite.length == 0)}>
                                <i className="fa fa-plus"></i>&nbsp;
                                { t('settings.integrations.wp.add_website') }
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
            {(setting?.integrations?.wp?.websites || []).map((extra, i) => (
                <Col key={ i } sm={ 12 } className="mt-1">
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">{ t('settings.integrations.wp.name') }</InputGroupAddon>
                        <Field className="form-control" name={`${name}[${i}].name`} component="input" type="text" />
                        <InputGroupAddon addonType="append">{ t('settings.integrations.wp.uri') }</InputGroupAddon>
                        <Field className="form-control" name={`${name}[${i}].uri`} component="input" type="text" />
                        <InputGroupAddon addonType="append">
                            <Button color="danger" onClick={ e => removeWebsite(i) }>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            ))}
            </Row>
        </div>
    );
}

export default IntegrationsWebsitesField;
