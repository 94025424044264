import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Loader, ItemsList, LanguageSelector, SendEmailModal, ModalComponent } from '../../../../components';
import { Form, Row, Col, Button, ButtonGroup, Card, CardBody, CardText, CardTitle } from "reactstrap";
import _ from "lodash";

import { Setting, EmailLayout } from '../../../../models';
import { EmailLayoutOptions } from './EmailLayoutOptions';
import { EmailWidgetOptions } from './EmailWidgetOptions';
import { EmailWidgetsBrowser } from './EmailWidgetsBrowser';
import { SortableList } from "./SortableList";
import { arrayMoveImmutable } from 'array-move';

import { resetEmailLayouts, loadEmailLayout, loadEmailLayouts, updateEmailLayout, createEmailLayout, duplicateEmailLayout, deleteEmailLayout } from '../../../../redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import './EmailLayouts.css';

import layoutsTemplates from '../../../../assets/templates/emails.json';

let EmailLayouts = (props) => {
    const { handleSubmit, submitting } = props;

    const { setting }                       = useSelector(state => state.settings);
    const { layouts, layout, isLoadingOne } = useSelector(state => state.layouts);

    const dispatch     = useDispatch();
    const { t, i18n }  = useTranslation();
    const { isMobile } = useSelector(state => state.ui);

    const { layoutId } = useParams();

    const [widgets, setWidgets]                         = useState([]);
    const [inEdition, setInEdition]                     = useState(false);
    const [editionGroup, setEditionGroup]               = useState('layout');
    const [sendModalIsVisible, showSendModal]           = useState(false);
    const [addModalVisible, showAddModal]               = useState(false);

    const [currentWidget, setCurrentWidget]             = useState(undefined);
    const [currentOptionsGroup, setCurrentOptionsGroup] = useState('options');
    const [previewLanguage, setPreviewLanguage]         = useState(i18n.language);

    // layout remove confirmation
    const [confirmationIsVisible, setConfirmationVisible] = useState(false);

    useEffect(() => {
        dispatch(resetEmailLayouts());
        dispatch(loadEmailLayouts());
    }, []);

    useEffect(() => {
        if (layoutId)
            dispatch(loadEmailLayout(layoutId));
    }, [dispatch, layoutId])

    useEffect(() => {
        if (layout && layout.layout_type === 'email')
            setWidgets(layout.widgets);
    }, [layout])

    useEffect(() => {
        if (!layout || !widgets)
            return;

        layout.widgets = [...widgets];
    }, [widgets]);

    const showAddLayoutModal = () => showAddModal(true);
    const addLayout = name => {
        dispatch(createEmailLayout(layoutsTemplates[name]));
        showAddModal(false);
    };

    const duplicate = async () => {
        await dispatch(duplicateEmailLayout(layout));
        dispatch(loadEmailLayouts());
    }

    const removeLayout = async () => {
        // reset current layout
        await dispatch(deleteEmailLayout(layout?._id));
        setCurrentWidget(undefined);
        setConfirmationVisible(false);
        dispatch(loadEmailLayouts());
    }

    const addWidget = (type, subType) => {
        if (!layout)
            return;

        const widget = EmailLayout.createWidget({ type, subType });
        layout.widgets.push(widget);
        dispatch(updateEmailLayout(layout));
        if (widget.isEditable())
            setTimeout(() => setCurrentWidget(widget), 200);
    };

    const duplicateWidget = () => {
        if (!layout)
            return;

        const newWidget = _.cloneDeep(currentWidget);
        delete newWidget.id;

        layout.widgets.push(newWidget);
        dispatch(updateEmailLayout(layout, (err, layout) => {
            if (!err)
                setCurrentWidget(layout.widgets[layout.widgets.length - 1]);
        }));
    };

    const deleteWidget = () => {
        if (!layout || !currentWidget)
            return;

        layout.widgets = layout.widgets.filter(w => w.id !== currentWidget.id);
        dispatch(updateEmailLayout(layout));
        setCurrentWidget(undefined);
    };

    const moveWidgetBackward = () => {
        if (!currentWidget)
            return;

        const newWidgets = [currentWidget].concat(
            widgets.filter(w => w.id != currentWidget.id)
        );
        setWidgets(newWidgets);
        setCurrentWidget(newWidgets[0]);
    };

    const moveWidgetForward = () => {
        if (!currentWidget)
            return;

        const newWidgets = widgets.filter(w => w.id != currentWidget.id).concat([
            currentWidget
        ]);
        setWidgets(newWidgets);
        setCurrentWidget(newWidgets[newWidgets.length - 1]);
    };

    const sortWidgets = (oldIndex, newIndex) => {
        const newWidgets = arrayMoveImmutable(widgets, oldIndex, newIndex);
        setWidgets(newWidgets);
    };

    const handleNewOptions = (widget) => {
        const newWidgets = _.cloneDeep(widgets)?.map(w => {
            if (w.id === widget.id)
                return { ...widget };
            return w;
        });;
        setWidgets(newWidgets);
    };

    const history = useHistory();
    const onSelectLayout = (_id) => {
        setCurrentWidget(undefined);

        if (_id === '__add')
            return showAddLayoutModal();

        history.push(`/email_layouts/edit/${_id}`);
    }

    const selectWidget = (widget, index) => {
        setCurrentWidget(undefined);
        // we let the options widget the time to reset
        setTimeout(() => {
            if (widget.isEditable()) {
                setEditionGroup('widgets');
                setCurrentWidget(widget);
            }
        }, 0);
    }

    const items = _.uniqBy((layouts || []), '_id').filter(l => l.layout_type === 'email')?.map((l, index) => {
        return {
            key: `${l.name[i18n.language]}.${index}`,
            title: l.name[i18n.language],
            icon: isLoadingOne && l._id === layoutId ? 'spinner fa-spin' : 'th',
            active: l._id === layoutId ? true : false,
            depth: 0,
            selection: l._id
        }
    });
    items.push({
        key: '__add',
        selection: '__add',
        title: t("layouts.add_new_layout"),
        icon: 'plus',
        isButton: true,
        depth: 0
    });

    const mainContainerWidth  = 21;
    const mainContainerHeight = 29.7;

    const bodyStyle = React.useMemo(() => {
        return `
        .contentWrapper {
            background-color: ${layout?.options?.style?.backgroundColor ?? '#FFFFFF'} !important;
            border: ${layout?.options?.style?.borderWidth}px ${layout?.options?.style?.borderStyle} ${layout?.options?.style?.borderColor};
            border-radius: ${layout?.options?.style?.borderRadius || 0}px;
        }`;
    }, [layout]);

    return (
        <div className="EmailLayouts">
            <style type="text/css">
                { bodyStyle }
            </style>
            <Card>
                <CardBody>
                        <CardText tag="div">
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col className="post_categories-browser" sm={2}>
                                        <div className="TypeSelector">
                                            <ItemsList
                                                title={t('layouts.layouts')}
                                                items={items}
                                                onSelect={onSelectLayout}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="post_categories-browser" sm={10}>
                                        {(layout?.layout_type === 'email') &&
                                            <>
                                                <div className={"d-flex justify-between topButtonsWrapper " + (isMobile ? "w-100" : "")}>
                                                    <div>
                                                        <ButtonGroup className={isMobile ? "w-100" : ""}>
                                                            <Button onClick={() => setInEdition(true)} color={inEdition ? 'primary' : 'dark'} size="sm">
                                                                <i className="fa fa-pencil"></i> {t("settings.pdf.edit_mode")}
                                                            </Button>
                                                            <Button onClick={() => setInEdition(false)} color={!inEdition ? 'primary' : 'dark'} size="sm">
                                                                <i className="fa fa-eye"></i> {t("settings.pdf.preview_mode.title")}
                                                            </Button>
                                                        </ButtonGroup>

                                                        <LanguageSelector onChange={lang => setPreviewLanguage(lang)} />
                                                        <Button onClick={() => showSendModal(true)} color="primary" size="sm" className="ml-2">
                                                            <i className="fa fa-envelope"></i> {t("layouts.send_test_email")}
                                                        </Button>
                                                    </div>
                                                    {inEdition && (
                                                        <div className="text-right">
                                                            <Button onClick={handleSubmit} type="submit" color="success" disabled={submitting || isLoadingOne}>
                                                                {isLoadingOne ?
                                                                    <span><i className="fa fa-spinner fa-spin"></i></span>
                                                                    :
                                                                    <span><i className="fa fa-save"></i>&nbsp; {t("common.save")} </span>
                                                                }
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                                <Row>
                                                    <Col sm={inEdition ? 6 : 12}>
                                                        <div className={"previewWrapper " + (isMobile ? "w-100" : "")}>
                                                            <div className="preview">
                                                                <div className="contentWrapper">
                                                                    { isLoadingOne ? (
                                                                        <div className="loader-wrapper">
                                                                            <Loader />
                                                                        </div>
                                                                    ) : (
                                                                        <SortableList
                                                                            layout={layout}
                                                                            widgets={widgets}
                                                                            currentWidget={currentWidget}
                                                                            inEdition={inEdition}
                                                                            lang={previewLanguage}
                                                                            setting={new Setting(setting)}
                                                                            onClick={(widget, index) => selectWidget(widget, index)}
                                                                            onSort={sortWidgets}
                                                                            onDelete={deleteWidget}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6}>
                                                        {inEdition && (
                                                        <>
                                                            <div className="topOptionsButtonsWrapper">
                                                                <ButtonGroup>
                                                                    <Button color={editionGroup === "widgets" ? 'primary' : 'dark'} onClick={() => setEditionGroup("widgets")}>
                                                                        <i className="fa fa-th" />{' '}
                                                                        { t('layouts.groups.widgets') }
                                                                    </Button>
                                                                    <Button color={editionGroup === "layout" ? 'primary' : 'dark'} onClick={() => setEditionGroup("layout")}> <i className="fa fa-cog" />{' '}
                                                                        { t('layouts.groups.layout') }
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </div>
                                                            {editionGroup === 'layout' &&(
                                                            <>
                                                                <EmailLayoutOptions onSubmit={ handleSubmit } />
                                                                <Card className="h-auto mb-2">
                                                                    <CardBody>
                                                                        <CardText tag="div">
                                                                            {!confirmationIsVisible && (
                                                                            <div className="d-flex justify-between">
                                                                                <Button onClick={() => duplicate()} color='info' size="sm">
                                                                                    <i className="fa fa-copy"></i> {t("layouts.duplicate_layout")}
                                                                                </Button>
                                                                                <Button color="danger" onClick={() => setConfirmationVisible(true)}>
                                                                                    <span>
                                                                                        <i className="fa fa-trash"></i>&nbsp;{t("layouts.delete_layout")}
                                                                                    </span>
                                                                                </Button>
                                                                            </div>
                                                                            )}
                                                                            {confirmationIsVisible && (
                                                                            <Row>
                                                                                <Col>
                                                                                    <b>{t('common.are_you_sure')}</b>&nbsp;
                                                                                    <ButtonGroup>
                                                                                        <Button onClick={() => setConfirmationVisible(false)} color='info' size="sm">
                                                                                            <i className="fa fa-times"></i> {t("common.no")}
                                                                                        </Button>
                                                                                        <Button type="submit" color="danger" onClick={removeLayout}>
                                                                                            <span>
                                                                                                <i className="fa fa-trash"></i>&nbsp;{t("common.yes")}
                                                                                            </span>
                                                                                        </Button>
                                                                                    </ButtonGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            )}
                                                                        </CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            </>
                                                            )}
                                                            {editionGroup === 'widgets' &&(
                                                            <>
                                                                {currentWidget ? (
                                                                    <>
                                                                        <CardTitle className="small">
                                                                            {t('layouts.edit_widget')} - {t(`layouts.${currentWidget.type}_${currentWidget.subType}`)}
                                                                        </CardTitle>
                                                                        <EmailWidgetOptions
                                                                            widget={EmailLayout.createWidget(currentWidget)}
                                                                            currentGroup={currentOptionsGroup}
                                                                            onGroupChange={setCurrentOptionsGroup}
                                                                            onSubmit={handleNewOptions}
                                                                            onDuplicate={duplicateWidget}
                                                                            onBackward={moveWidgetBackward}
                                                                            onForward={moveWidgetForward}
                                                                            onDelete={deleteWidget}
                                                                            onClose={() => setCurrentWidget(undefined)}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <CardTitle className="small">
                                                                            {t('layouts.add_widget')}
                                                                        </CardTitle>
                                                                        <EmailWidgetsBrowser addWidget={addWidget} />
                                                                    </>
                                                                )}
                                                            </>
                                                            )}
                                                        </>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </CardText>
                </CardBody>
            </Card>
            <SendEmailModal
                isOpen={sendModalIsVisible}
                layout={layout}
                onClose={() => showSendModal(false)}
            />
            {addModalVisible && (
                <ModalComponent onClose={() => showAddModal(false)} title={t('layouts.add_from_template')}>
                    <div className="widget-chooser">
                        <div className="modal-choices">
                            {Object.keys(layoutsTemplates).map(name => (
                                <div key={name} className="tab-choice" onClick={() => addLayout(name)} >
                                    <p className="tab-choice-title">
                                        {t(`layouts.templates.${name}`)}
                                    </p>
                                    <p className="tab-choice-desc">
                                        {t(`layouts.templates.${name}_desc`)}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </ModalComponent>
                )}
        </div>
    );
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
EmailLayouts = reduxForm({
  form: 'layoutForm', // a unique identifier for this form
  enableReinitialize: true
})(EmailLayouts);

EmailLayouts = connect(state => ({ initialValues: state.layouts.layout, }), {})(EmailLayouts);

export default EmailLayouts;
