import React, { useState }  from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Label,
    Input,
    ButtonGroup,
    Button,
    CardText,
    CardFooter,
} from 'reactstrap';
import { ChromePicker } from 'react-color';
import { EmailLayout } from '../../../../../models';
import { PaddingInput, TranslatableWysiwyg } from '../../../../../components';

import imagePlaceholder from '../../../../../assets/images/imagePlaceholder.png';

const EmailWidgetOptions = (props) => {
    const {
        widget, currentGroup, onSubmit, onGroupChange, onDelete,
        onDuplicate, onClose,
    } = props;

    const { isMobile } = useSelector(state => state.ui);

    const { t } = useTranslation();

    const [group, setGroup] = useState(currentGroup || "options");

    const setOption   = (option, value) => { widget.options[option] = value; saneSubmit(); }
    const handleStyle = (property, value) => setOption('style', { ...(widget.options?.style || {}), [property]: value });

    const saneSubmit = () => {
        // remove Widget instance prototype...
        onSubmit(widget.toPlainObject());
    }

    const showGroup = group => {
        setGroup(group);
        onGroupChange(group);
    }

    const handleColorWithTransparency = (color, type) => {
        if (color.rgb.a === 1)
            return handleStyle(type, color.hex);

        const percent = color.rgb.a * 100;
        const intValue = Math.round(percent / 100 * 255); // map percent to nearest integer (0 - 255)
        const hexValue = intValue.toString(16); // get hexadecimal representation
        const hexTransparency = hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters

        handleStyle(type, `${color.hex}${hexTransparency}`);
    };

    const selectImage = e => {
        const file = e.target.files[0];
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setOption('rfc2397_data', fileReader.result);
        }
        fileReader.readAsDataURL(file)
    };

    const hasOptions = widget => {
        return (
            widget.handleOption(EmailLayout.OPTION_CONTACT_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_SCREENING_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_TICKET_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_TICKETTYPE_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_CUSTOMER_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_ENVIRONMENT_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_IMAGE_TYPE) ||
            widget.handleOption(EmailLayout.OPTION_DATE_FORMAT) ||
            widget.handleOption(EmailLayout.OPTION_TEXT) ||
            widget.handleOption(EmailLayout.OPTION_LINK) ||
            widget.handleOption(EmailLayout.OPTION_IMAGE) ||
            widget.handleOption(EmailLayout.OPTION_OBJECT_FIT)
        );
    }

    return (
        <div className="wrapper-1 mr-1">
            <div className="">
                <CardText tag="div" className={"mb-4" + (isMobile ? " d-flex flex-column-reverse" : "")}>
                    <ButtonGroup>
                        <Button color={group === "options" ? 'primary' : 'dark'} onClick={() => showGroup("options")}> <i className="fa fa-cog" />{' '}
                            { t('layouts.widget_options.groups.options') }
                        </Button>
                        <Button color={group === "style" ? 'primary' : 'dark'} onClick={() => showGroup("style")}>
                            <i className="fa fa-pencil" />{' '}
                            { t('layouts.widget_options.groups.style') }
                        </Button>
                    </ButtonGroup>
                    <Button type="button" color="danger" className="pull-right" onClick = { () => onClose() }>
                        <span>
                            <i className="fa fa-times"></i>
                        </span>
                    </Button>
                </CardText>

                { group === "options" && !hasOptions(widget) && (
                    <CardText tag="div" className="mb-3 text-center">
                        <b>
                            <i className="fa fa-info-circle"></i>&nbsp;
                            { t('layouts.no_options') }
                        </b>
                    </CardText>
                )}

                { group === "options" && hasOptions(widget) && (
                <CardText tag="div">
                    { widget.handleOption(EmailLayout.OPTION_IMAGE_TYPE) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.image_type")}</Label>
                            <Input defaultValue={widget.options?.type} type="select" onChange={ e => setOption('type', e.target.value) }>
                                <option>{t("layouts.image_type_placeholder")}</option>
                                {EmailLayout.imageTypes().map(type => (
                                    <option key={ type } value={ type }>
                                        {t(`common.image_types.${type}`)}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(EmailLayout.OPTION_TEXT) && (
                    <Row>
                        <Col>
                            <TranslatableWysiwyg
                                defaultValue={ widget.options?.text }
                                onChange={ text => setOption('text', text) }
                                withShortcodes
                                withColors
                                withImages
                                withTables
                            />
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(EmailLayout.OPTION_IMAGE) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.click_to_choose_image")}</Label>
                            <div className="imageOption">
                                <img
                                    src={ widget.options?.rfc2397_data ? widget.options.rfc2397_data : imagePlaceholder }
                                />
                                <input className="file_input" type="file" accept="image/*" onChange={ e => selectImage(e) } />
                            </div>
                        </Col>
                    </Row>
                    )}
                </CardText>
                )}
                { group === "style" && hasOptions(widget) && (
                <CardText tag="div">
                    { (widget.handleOption(EmailLayout.OPTION_COLOR) || widget.handleOption(EmailLayout.OPTION_LINKS_COLOR)|| widget.handleOption(EmailLayout.OPTION_BACKGROUND_COLOR) || widget.handleOption(EmailLayout.OPTION_BORDER_COLOR)) && (
                        <Row>
                        { widget.handleOption(EmailLayout.OPTION_COLOR) && (
                            <Col sm={12} lg={6}>
                                <Label> {t("layouts.color")}</Label>
                                <ChromePicker
                                    color={ widget.options?.style?.color }
                                    onChange={ color => handleStyle('color', color.hex)  }
                                />
                            </Col>
                        )}
                        { widget.handleOption(EmailLayout.OPTION_LINKS_COLOR) && (
                            <Col sm={12} lg={6}>
                                <Label> {t("layouts.linksColor")}</Label>
                                <ChromePicker
                                    color={ widget.options?.style?.linksColor }
                                    onChange={ color => handleStyle('linksColor', color.hex)  }
                                />
                            </Col>
                        )}
                        { widget.handleOption(EmailLayout.OPTION_BACKGROUND_COLOR) && (
                            <Col sm={12} lg={6}>
                                <Label> {t("layouts.background_color")}</Label>
                                <ChromePicker
                                    color={ widget.options?.style?.backgroundColor }
                                    onChange={ color => handleColorWithTransparency(color, 'backgroundColor') }
                                />
                            </Col>
                        )}
                        { widget.handleOption(EmailLayout.OPTION_BORDER_COLOR) && (
                            <Col sm={12} lg={6}>
                                <Label> {t("layouts.border_color")}</Label>
                                <ChromePicker
                                    color={ widget.options?.style?.borderColor }
                                    onChange={ color => handleStyle('borderColor', color.hex)  }
                                />
                            </Col>
                        )}
                        </Row>
                    )}
                    { (widget.handleOption(EmailLayout.OPTION_BORDER_WIDTH) || widget.handleOption(EmailLayout.OPTION_BORDER_STYLE)) && (
                    <Row>
                        { widget.handleOption(EmailLayout.OPTION_BORDER_WIDTH) && (
                        <Col sm={6}>
                          <Label>{t('layouts.border_width')}</Label>
                          <Input
                              type="number"
                              min = { 0 }
                              defaultValue={ widget.options?.style?.borderWidth }
                              onChange={ e => handleStyle('borderWidth', e.target.value)}
                          />
                        </Col>
                        )}
                        { widget.handleOption(EmailLayout.OPTION_BORDER_STYLE) && (
                        <Col sm={6}>
                            <Label> {t("layouts.border_style")}</Label>
                            <Input defaultValue={widget.options?.style?.borderStyle} type="select" onChange={ e => handleStyle('borderStyle', e.target.value) }>
                                <option>{t("layouts.border_style_placeholder")}</option>
                                {EmailLayout.borderStyleValues().map(value => (
                                    <option key={ value } value={ value }>
                                        { t(`layouts.border_style_${value}`) }
                                    </option>
                                ))}
                            </Input>
                        </Col>
                        )}
                    </Row>
                    )}
                    { widget.handleOption(EmailLayout.OPTION_BORDER_RADIUS) && (
                    <Row>
                        <Col>
                          <Label>{ t('layouts.border_radius') }</Label>
                          <Input
                              type="number"
                              min = { 0 }
                              defaultValue={ widget.options?.style?.borderRadius || 0 }
                              onChange={ e => handleStyle('borderRadius', e.target.value)}
                          />
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(EmailLayout.OPTION_PADDING) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.padding")}</Label>
                            <PaddingInput
                                defaultValue={ widget.options?.style?.padding || {}}
                                step={ 0.01 }
                                onChange={padding => handleStyle('padding', padding) }
                            />
                        </Col>
                    </Row>
                    )}
                </CardText>
                )}
                <CardFooter>
                    <Row>
                        <Col className={"text-" + (isMobile ? "center m-1": "left")}>
                            <ButtonGroup>
                                <Button type="button" color="danger" onClick = { () => onDelete() }>
                                    <span>
                                        <i className="fa fa-trash"></i>&nbsp;{t("layouts.delete_widget")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col className={"text-" + (isMobile ? "center m-1": "right")}>
                            <ButtonGroup>
                                <Button type="button" color="info" onClick = { () => onDuplicate() }>
                                    <span>
                                        <i className="fa fa-copy"></i>&nbsp;{t("layouts.duplicate_widget")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </CardFooter>
            </div>
        </div>
    );
}

export default EmailWidgetOptions;
