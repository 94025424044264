import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import store, { swInstalled, swHasUpdate } from './redux';
import App from './containers/App/App';
import registerServiceWorker from './registerServiceWorker';

import i18n from './locales/i18n';

import { BrowserRouter as Router } from 'react-router-dom'

import 'semantic-ui-css/semantic.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';

import infos from '../package.json';

import TimeAgo from 'javascript-time-ago';
import fr from 'javascript-time-ago/locale/fr';
import en from 'javascript-time-ago/locale/en';
import de from 'javascript-time-ago/locale/de';
TimeAgo.addDefaultLocale(fr)
TimeAgo.addLocale(en)
TimeAgo.addLocale(de)

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://4d6e4b303b0c0a0a9f21828f6b3e5c3d@sentry.neto2.net/5',
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInput: false,
                blockAllMedia: false
            })
        ],
        environment: process.env.NODE_ENV,
        release: infos.version,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.25,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <Router store={store}>
            <App/>
        </Router>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker({
    reset: false,
    onInstall: reg => store.dispatch(swHasUpdate(reg)),
    onUpdate: reg => store.dispatch(swHasUpdate(reg)),
});
