import BaseModel from './BaseModel';
import Task from './Task';
import moment from 'moment';

/** Class representing a shift. */
class Shift extends BaseModel {
    constructor(properties) {
        super(properties);

        this.start_at = moment(this.start_at);
        this.stop_at  = moment(this.stop_at);
    }
}

export default Shift;
