import React from 'react';
import { Field } from 'redux-form'
import { Dropdown } from 'semantic-ui-react';

import './RefField.css';
import eventival from '../../assets/images/integrations/eventival.png';

const RefField = ({ name, refs }) => {

    const Component = (props) => {
        const options = (refs || []).map(ref => {
            const parts = [
                ref.name ?? ref.id,
                ref.description
            ];
            return {
                key: ref.id,
                text: parts.filter(v => !!v).join(' - '),
                value: ref.id,
                image: { avatar: true, circular: true, src: eventival, className: "mini" },
            };
        });
        const { input } = props;

        const onChange = (e, data) => {
            input.onChange(data.value.map(id => refs.find(r => r.id === id)));
        }

        return <Dropdown
            className="small"
            name={name}
            options={options}
            value={(input.value || []).filter(ref => !!ref).map(ref => ref.id)}
            onChange={ onChange }
            noResultsMessage=""
            search
            clearable
            fluid
            multiple
            selection
        />;
    }

    return (
        <div className="RefField">
            <Field multiple name={ name } component={ Component } className="form-control" />
        </div>
    );
}

export default RefField;
