import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

const PolicyRefundField = ({ name, defaultValue }) => {
    const { t } = useTranslation();
    const options = [
        { value: 'always', text: t('settings.pos.refund_policy.always') },
        { value: 'only_unclosed', text: t('settings.pos.refund_policy.only_unclosed') },
        { value: 'never', text: t('settings.pos.refund_policy.never') },
    ];
    const Component = ({ input }) => (
      <select {...input} className="form-control">
          {options.map(option => (
            <option key={option.value} value={option.value}>
                {option.text}
            </option>
          ))}
      </select>
    );
    return (
      <div className="SelectField">
          <Field
            name={name}
            component={Component}
            className="form-control"
          />
      </div>
    );
};

export default PolicyRefundField;