import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { startCreateMap } from '../../../redux/modules/places';
import { CopyButton } from '../../../components';
import classnames from 'classnames';

import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'
import PlaceMap from '../PlaceMap/PlaceMap'
import './PlaceForm.css';

moment.locale('fr');
momentLocalizer();

let PlaceForm = ({ handleSubmit, pristine, submitting }) => {
    const { t }                     = useTranslation();
    const [activeTab, setActiveTab] = useState('informations');
    const { isLoading, place }      = useSelector(state => state.places);
    const { user }                  = useSelector(state => state.auth);
    const { setting }               = useSelector(state => state.settings);

    const hasJsonIntegration      = setting && user?.tkt_instance?.hasJsonIntegration(setting);
    const hasEventivalIntegration = setting && user?.tkt_instance?.hasEventivalIntegration(setting);
    const readOnly                = hasJsonIntegration || hasEventivalIntegration;

    const toggle = tab => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const dispatch = useDispatch();
    const addMap = () => {
        dispatch(startCreateMap());
    }

    return (
        <Form className="PlaceForm" onSubmit={handleSubmit}>
            <Row>
                <Col>
                    {hasEventivalIntegration &&
                        <div className="alertMessage alert alert-danger"> {t("places.eventival_form_warning")}</div>
                    }
                    {hasJsonIntegration &&
                        <div className="alertMessage alert alert-danger"> {t("places.json_form_warning")}</div>
                    }
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === 'informations' })} onClick={() => { toggle('informations'); }}>
                                { t('places.informations') }
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === 'maps' })} onClick={() => { toggle('maps'); }}>
                                { t('places.maps') }
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="informations">
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <div className="d-sm-flex justify-content-sm-between">
                                            <span>
                                                <i className="fa fa-info-circle"></i>&nbsp;{t("places.informations")}
                                            </span>
                                            <span>
                                                {place?.refs?.length > 0 && place.refs.map((ref, i) => (
                                                    <CopyButton
                                                        key={ i }
                                                        badge
                                                        size="sm"
                                                        rightComponent={ ref.icone() ? <img src={ref.icone()} className="img rounded w-1.5 ml-2" /> : null}
                                                        color="secondary"
                                                        className="right-20 text-small ml-2"
                                                        textToCopy={ ref.display() }
                                                    />
                                                ))}
                                            </span>
                                        </div>
                                    </CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("places.number")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="number" step="1" min="0" name="number" id="number" autoComplete="number" placeholder={t("places.number_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("places.building")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="cinema" id="cinema" autoComplete="name" placeholder={t("places.building_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("places.name")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="name" id="name" autoComplete="name" placeholder={t("places.name_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="address" sm={3}>{t("places.address")}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="address" id="address" autoComplete="address" placeholder={t("places.address_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="zip" sm={3}>{t("places.zip")}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="zip" id="zip" autoComplete="zip" placeholder={t("places.zip_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="city" sm={3}>{t("places.city")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="city" id="city" autoComplete="city" placeholder={t("places.city_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="state" sm={3}>{t("places.state")}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="state" id="state" autoComplete="state" placeholder={t("places.state_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="country" sm={3}>{t("places.country")}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="country" id="country" autoComplete="country" placeholder={t("places.country_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="coordinates" sm={3}>{t("places.coordinates")}</Label>
                                            <Col sm={9}>
                                                <Row className="mb-0">
                                                    <Col>
                                                        <Field component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="coordinates.lat" id="coordinates.lat" autoComplete="coordinates.lat" placeholder={t("places.coordinates_lat_placeholder")} readOnly={readOnly} />
                                                    </Col>
                                                    <Col>
                                                        <Field component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="text" name="coordinates.long" id="coordinates.long" autoComplete="coordinates.long" placeholder={t("places.coordinates_long_placeholder")} readOnly={readOnly} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="capacity.total" sm={3}>{t("places.capacity_total")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className={`form-control ${readOnly ? "readOnly" : ""}`} type="number" min="0" name="capacity.total" id="capacity.total" autoComplete="capacity" placeholder={t("places.capacity_total_placeholder")} readOnly={readOnly} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="capacity.prm" sm={3}>{t("places.capacity_prm")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="number" min="0" name="capacity.prm" id="capacity.prm" autoComplete="capacity" placeholder={t("places.capacity_prm_placeholder")} />
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="maps">
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-map-marker"></i>&nbsp;{t("places.maps")}</CardTitle>
                                    <CardText tag="div">
                                        { place && place.map && (
                                        <div className="p-3 bg-white text-dark">
                                            <Field isLoading={ isLoading } component={ PlaceMap } name="map" />
                                        </div>
                                        )}
                                        { place && !place.map && (
                                        <Button type="button" color="success" disabled={ submitting || isLoading} onClick={ () => addMap() }>
                                            {isLoading ?
                                                <span>
                                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                </span>
                                                :
                                                <span>
                                                    <i className="fa fa-plus"></i>&nbsp;
                                                    {t("places.add_map")}
                                                </span>
                                            }
                                        </Button>
                                        )}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col className="text-center">
                    <br/>
                    <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                        {isLoading ?
                            <span>
                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            <span>
                                <i className="fa fa-save"></i>&nbsp;
                                {t("common.save")}
                            </span>
                        }
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

const mapDispatchToProps = {}
PlaceForm = reduxForm({ form: 'placeForm' })(PlaceForm)
PlaceForm = connect(
  state => ({ initialValues: state.places.place }), {}
)(PlaceForm)

export default PlaceForm;
