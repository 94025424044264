import React, {useEffect} from 'react';
import { Badge, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { Utils } from '../../services';
import moment from 'moment';

import './BucketsTable.css';
import {loadTickettypes} from "../../redux";

let bucketIndex = 0;
const colors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];
const bucketColors = {};

const BucketsTable = ({ buckets }) => {
    const { t, i18n }          = useTranslation();
    const { isMobile }         = useSelector(state => state.ui);
    const { tickettypes = [] } = useSelector(state => state.tickettypes || {});
    const dispatch             = useDispatch();

    useEffect(() => {
        if (!tickettypes?.length) {
            dispatch(loadTickettypes());
        }
    }, [dispatch, tickettypes]);

    const role_name = (role) => {
        return t(`roles.${role}`, { defaultValue: role });
    };

    const tickettype_name = _id => {
        const type = tickettypes.find(t => t._id === _id);
        if (!type)
            return _id;

        return Utils.localized_or_fallback(type.name, i18n.language);
    };

    if (!buckets)
        return null;

    const is_stash_bucket = (bucket) => {
        if (bucket.rules.not_after?.length > 0) {
            return new Date(bucket.rules.not_after).getTime() === 0;
        }
        return false;
    }

    const readable_not_before = not_before => {
        if (!not_before)
            return <i>{ t("bucketlists.bucket.since_always") }</i>;

        if (['start_at', 'stop_at'].includes(not_before))
            return <i>{ t(`bucketlists.bucket.${not_before}`) }</i>;

        return not_before;
    };

    const readable_not_after = not_after => {
        if (!not_after)
            return <i>{ t("bucketlists.bucket.for_ever") }</i>;

        if (['start_at', 'stop_at'].includes(not_after))
            return <i>{ t(`bucketlists.bucket.${not_after}`) }</i>;

        return not_after;
    };

    const readable_capacity = capacity => {
        if (capacity === 'remainder')
            return <i>{ t("bucketlists.bucket.remainder") }</i>;

        if (capacity === 'prm_capacity')
            return <i>{ t("bucketlists.bucket.prm_capacity") }</i>;

        if ((capacity + "").match(/prm_capacity/))
            return <i>{ capacity.replaceAll('prm_capacity', `(${t("bucketlists.bucket.prm_capacity")})`) }</i>;

        if (capacity === 'total_capacity')
            return <i>{ t("bucketlists.bucket.full_capacity") }</i>;

        if ((capacity + "").match(/total_capacity/))
            return <i>{ capacity.replaceAll('total_capacity', `(${t("bucketlists.bucket.full_capacity")})`) }</i>;

        return capacity;
    };

    const bucketColor = _id => {
        if (!(_id in bucketColors)) {
            bucketColors[_id] = colors[bucketIndex % colors.length];
            bucketIndex ++;
        }

        return bucketColors[_id];
    }

    if (!isMobile)
        return (
            <Card>
                <CardBody>
                    <div className="cards">
                        <table border="1" cellPadding="5" className="w-100 border border-white p-1 tableQuotas">
                            <thead>
                                <tr>
                                    <th rowSpan={2}>{t("screenings.edit.form.buckets.id")}</th>
                                    <th colSpan={4}>{t("screenings.edit.form.buckets.rules")}</th>
                                    <th rowSpan={2}>{t("screenings.edit.form.buckets.can_take_from")}</th>
                                    <th className="capacityCol" rowSpan={2}>
                                        {t("screenings.edit.form.buckets.capacity")}
                                    </th>
                                </tr>
                                <tr>
                                    <th>{t("screenings.edit.form.buckets.sellers")}</th>
                                    <th>{t("screenings.edit.form.buckets.not_before")}</th>
                                    <th>{t("screenings.edit.form.buckets.not_after")}</th>
                                    <th>{t("screenings.edit.form.buckets.tickets")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    buckets.map((b, index) => {
                                        return [
                                            <tr key={index}>
                                                <td><Badge color={ bucketColor(b._id) }>{b._id}</Badge></td>
                                                {!is_stash_bucket(b) ?
                                                    <>
                                                        <td>{b.rules.only_for_roles ? (
                                                            b.rules.only_for_roles.map(role => (
                                                                <p key={role} className="my-0">{role_name(role)}</p>
                                                            )) ?? <i>{t("screenings.edit.form.buckets.no_role")}</i>
                                                        ) : <i>{t("screenings.edit.form.buckets.all_roles")}</i>}</td>
                                                        <td>{readable_not_before(b.rules.not_before)}</td>
                                                        <td>{readable_not_after(b.rules.not_after)}</td>
                                                        <td>{(b.rules.only_for_tickettypes) ? (b.rules.only_for_tickettypes.map(_id =>
                                                                <p key={_id}
                                                                   className="my-0">{tickettype_name(_id) || _id}</p>
                                                            ) ?? <i>{t("screenings.edit.form.buckets.no_tickettype")}</i>) :
                                                            <i>{t("screenings.edit.form.buckets.all_tickettypes")}</i>}</td>
                                                        <td>{(b.may_steal_from) ? b.may_steal_from.map(r => <Badge
                                                            color={bucketColor(r)} key={r}
                                                            className="my-0 mr-1">{r}</Badge>) : "n/a"}</td>
                                                    </>
                                                    :
                                                    <td className='text-center' colSpan={5}>
                                                        <span><i>{t("screenings.edit.form.buckets.stash_bucket")}</i></span>
                                                    </td>
                                                }
                                                <td className='text-center'><span>{ readable_capacity(b.total_capacity) }</span></td>
                                            </tr>
                                        ]
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )

    // Mobile version
    return (
        <>
            <h4 className='text-center'>
                <b>
                    {t("screenings.edit.form.buckets.total_capacity")}: {buckets.reduce((total, item) => total + item.total_capacity, 0)}
                </b>
            </h4>
            {buckets.map((b, index) =>
                <Card key={b._id} className='border mb-2'>
                    <CardHeader className='border-bottom'>
                        <h4 className='text-center'><Badge color={ bucketColor(b._id) }>{b._id}</Badge></h4>
                    </CardHeader>
                    <CardBody>
                        <Row className='mb-0'>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.sellers")}</b>
                            </Col>
                            <Col xs={6} className="text-right">
                                {b.rules.only_for_roles ? b.rules.only_for_roles.map(r => { return <span key={r} className="my-0 mr-1">{role_name(r)}</span> }) : "n/a"}
                            </Col>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.not_before")}</b>
                            </Col>
                            <Col xs={6} className="text-right">
                                {b.rules.not_before ?? "n/a"}
                            </Col>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.not_after")}</b>
                            </Col>
                            <Col xs={6} className="text-right">
                                {b.rules.not_after ?? "n/a"}
                            </Col>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.tickets")}</b>
                            </Col>
                            <Col xs={6} className="text-right">
                                {(b.rules.only_for_tickettypes) ? b.rules.only_for_tickettypes.map(r => <p key={r} className="my-0 mr-1">{ tickettype_name(r)}</p> ) : "n/a"}
                            </Col>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.can_take_from")}</b>
                            </Col>
                            <Col xs={6} className="text-right pb-2">
                                {(b.may_steal_from) ? b.may_steal_from.map(r => <p key={r} className="my-0 mr-1">{r}</p>) : "n/a"}
                            </Col>
                            <Col xs={12} className='border-top pt-2'>
                                <h5>{t("places.capacity")}<span className="float-right">{b.total_capacity}</span></h5>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </>
    )
}

export default BucketsTable;
