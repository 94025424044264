import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import {
    Row, Col, ButtonDropdown, DropdownToggle,
    DropdownMenu, DropdownItem, Card, CardBody,
    Badge, Button, InputGroup, CardHeader
} from 'reactstrap';
import ScreeningFormGeneralField from '../ScreeningFormGeneralField/ScreeningFormGeneralField';
import moment from 'moment';
import { FillBar } from '../../../../components';
import { loadBucketlists, updateScreeningProperty, loadTickettypes } from '../../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { Utils } from '../../../../services';

let bucketIndex = 0;
const colors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];
const bucketColors = {};

const ScreeningFormBuckets = ({ buckets, isLoading }) => {
    const { t, i18n }         = useTranslation();
    const dispatch            = useDispatch();
    const { isMobile }        = useSelector(state => state.ui);
    const { screening }       = useSelector(state => state.screenings);
    const { bucketlists }     = useSelector(state => state.bucketlists);
    const [isOpen, setIsOpen] = useState(false);
    const { tickettypes = [] } = useSelector(state => state.tickettypes || {});

    const internalQuotaIds = ["overbooking", "no-show"];

    useEffect(() => {
        dispatch(loadBucketlists());
        if (!tickettypes?.length) {
            dispatch(loadTickettypes());
        }
    }, [dispatch, tickettypes]);

    const updateBuckets = (values, index) => {
        const payload = { _id: values._id };
        payload.buckets = [values.buckets[index]];
        dispatch(updateScreeningProperty(payload, index));
    }

    const applyBuckets = id => {
        const payload = { _id: screening._id };
        payload.bucketlist_id = id;
        dispatch(updateScreeningProperty(payload, 'bucketlist_id'));
    }

    const role_name = (role) => {
        return t(`roles.${role}`, { defaultValue: role });
    };

    const tickettype_name = _id => {
        const type = tickettypes.find(t => t._id === _id);
        if (!type)
            return _id;

        return Utils.localized_or_fallback(type.name, i18n.language);
    };

    const is_stash_bucket = (bucket) => {
        if (bucket.rules.not_after?.length > 0) {
            return new Date(bucket.rules.not_after).getTime() === 0;
        }
        return false;
    }

    const readable_not_before = not_before => {
        if (!not_before)
            return <i>{ t("bucketlists.bucket.since_always") }</i>;

        if (!screening)
            return moment(not_before).format('lll');

        if (moment(screening.start_at).isSame(moment(not_before)))
            return <i>{ t('bucketlists.bucket.start_at') }</i>;

        if (moment(screening.stop_at).isSame(moment(not_before)))
            return <i>{ t('bucketlists.bucket.stop_at') }</i>;

        return moment(not_before).format('lll');
    };

    const readable_not_after = not_after => {
        if (!not_after)
            return <i>{ t("bucketlists.bucket.for_ever") }</i>;

        if (!screening)
            return moment(not_after).format('lll');

        if (moment(screening.start_at).isSame(moment(not_after)))
            return <i>{ t('bucketlists.bucket.start_at') }</i>;

        if (moment(screening.stop_at).isSame(moment(not_after)))
            return <i>{ t('bucketlists.bucket.stop_at') }</i>;

        return moment(not_after).format('lll');
    };

    const bucketColor = _id => {
        if (!(_id in bucketColors)) {
            bucketColors[_id] = colors[bucketIndex % colors.length];
            bucketIndex ++;
        }

        return bucketColors[_id];
    }

    const applyBucketListField = () => {
        if (!bucketlists?.length)
            return null;

        return (
            <Row className="mb-3">
                <Col>
                    <div className="float-right">
                        <ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                            <DropdownToggle caret color="primary">{ t('bucketlists.apply') }</DropdownToggle>
                            <DropdownMenu right>
                                {bucketlists.map(list => (
                                    <DropdownItem key={ list._id } onClick={() => applyBuckets(list._id)}>
                                        {list.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                </Col>
            </Row>
        );
    }

    if (!isMobile)
        return (
            <Card>
                <CardBody>
                    <div className="cards">
                        { applyBucketListField() }
                        <table className="border border-white p-1 tableQuotas">
                            <thead>
                                <tr>
                                    <th rowSpan={2}>{t("screenings.edit.form.buckets.id")}</th>
                                    <th colSpan={4}>{t("screenings.edit.form.buckets.rules")}</th>
                                    <th rowSpan={2}>{t("screenings.edit.form.buckets.can_take_from")}</th>
                                    <th className="bookingsCol" rowSpan={2}>{t("screenings.edit.form.buckets.bookings")}</th>
                                    <th className="capacityCol" rowSpan={2}>{t("screenings.edit.form.buckets.capacity")} ({t("screenings.edit.form.buckets.total_capacity")}={buckets.reduce((total, item) => total + item.total_capacity, 0)})</th>
                                </tr>
                                <tr>
                                    <th>{t("screenings.edit.form.buckets.sellers")}</th>
                                    <th>{t("screenings.edit.form.buckets.not_before")}</th>
                                    <th>{t("screenings.edit.form.buckets.not_after")}</th>
                                    <th>{t("screenings.edit.form.buckets.tickets")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    buckets.map((b, index) => {
                                        return [
                                            <tr key={index}>
                                                <td><Badge color={ bucketColor(b._id) }>{b._id}</Badge></td>
                                                {!is_stash_bucket(b) ?
                                                  <>
                                                      <td>{b.rules.only_for_roles ? (b.rules.only_for_roles.map(role => (
                                                        <p key={role} className="my-0">{role_name(role)}</p>
                                                      )) ?? <i>{t("screenings.edit.form.buckets.no_role")}</i>) : <i>{t("screenings.edit.form.buckets.all_roles")}</i>}</td>
                                                      <td>{ readable_not_before(b.rules.not_before) }</td>
                                                      <td>{ readable_not_after(b.rules.not_after) }</td>
                                                      <td>{(b.rules.only_for_tickettypes) ? (b.rules.only_for_tickettypes.map(_id => (
                                                        <p key={_id} className="my-0">{tickettype_name(_id)}</p>
                                                      )) ?? <i>{t("screenings.edit.form.buckets.no_tickettype")}</i>) : <i>{t("screenings.edit.form.buckets.all_tickettypes")}</i>}</td>
                                                      <td>{(b.may_steal_from) ? b.may_steal_from.map(r => <Badge key={r} color={ bucketColor(r) } className="my-0 mr-1">{r}</Badge>) : "n/a"}</td>
                                                  </>
                                                :
                                                    <td className='text-center' colSpan={5}><span><i>{t("screenings.edit.form.buckets.stash_bucket")}</i></span></td>
                                                }

                                                <td>
                                                    {b.total_capacity > 0 ?
                                                        <FillBar
                                                            clickable
                                                            bars={[
                                                                {
                                                                    width: b.confirmed * 100 / b.total_capacity,
                                                                    color: "red",
                                                                    text: t("screenings.list.confirmed"),
                                                                    baseInt: b.confirmed
                                                                },
                                                                {
                                                                    width: b.unconfirmed * 100 / b.total_capacity,
                                                                    color: "#A3A300",
                                                                    text: t("screenings.list.unconfirmed"),
                                                                    baseInt: b.unconfirmed
                                                                },
                                                                {
                                                                    width: b.available * 100 / b.total_capacity,
                                                                    color: "green",
                                                                    text: t("screenings.list.available"),
                                                                    baseInt: b.available
                                                                },
                                                                {
                                                                    width: 0,
                                                                    color: "black",
                                                                    text: t("screenings.list.total"),
                                                                    baseInt: b.total_capacity
                                                                }
                                                            ]}
                                                        />
                                                        :
                                                        "n/a"}
                                                </td>
                                                <td>
                                                    <Row>
                                                        <Col>
                                                            {!internalQuotaIds.includes(b._id) ?
                                                                <ScreeningFormGeneralField onSubmit={(values) => updateBuckets(values, index)}>
                                                                    <InputGroup>
                                                                        <Field component="input" type="number" className="form-control" name={`buckets[${index}].total_capacity`} id="total_capacity" placeholder="totalcap" />
                                                                        <Button type="submit" size="sm" color="success" disabled={isLoading}>
                                                                            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'save'}`}></i>
                                                                        </Button>
                                                                    </InputGroup>
                                                                </ScreeningFormGeneralField>
                                                                :
                                                                <span><i>{t("screenings.edit.form.buckets.bucket_intern")}</i></span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                        ]
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )

    // Mobile version
    return (
        <>
            <h4 className='text-center'>
                <b>
                    {t("screenings.edit.form.buckets.total_capacity")}: {buckets.reduce((total, item) => total + item.total_capacity, 0)}
                </b>
            </h4>
            { applyBucketListField() }
            {buckets.map((b, index) =>
                <Card key={b._id} className='border mb-2'>
                    <CardHeader className='border-bottom'>
                        <h4 className='text-center'><Badge color={ bucketColor(b._id) }>{b._id}</Badge></h4>
                    </CardHeader>
                    <CardBody>
                        <Row className='mb-0'>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.sellers")}</b>
                            </Col>
                            <Col xs={6} className="text-right">
                                {b.rules.only_for_roles ? b.rules.only_for_roles.map(role => (
                                  <span key={role} className="my-0">{role_name(role)}</span>
                                )) : "n/a"}
                            </Col>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.not_before")}</b>
                            </Col>
                            <Col xs={6} className="text-right">
                                {b.rules.not_before ? moment(b.rules.not_before).format("lll") : "n/a"}
                            </Col>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.not_after")}</b>
                            </Col>
                            <Col xs={6} className="text-right">
                                {b.rules.not_after ? moment(b.rules.not_after).format("lll") : "n/a"}
                            </Col>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.tickets")}</b>
                            </Col>
                            <Col xs={6} className="text-right">
                                {(b.rules.only_for_tickettypes) ? b.rules.only_for_tickettypes.map(_id => (
                                  <p key={_id} className="my-0">{tickettype_name(_id)}</p>
                                )) : "n/a"}
                            </Col>
                            <Col xs={6}>
                                <b>{t("screenings.edit.form.buckets.can_take_from")}</b>
                            </Col>
                            <Col xs={6} className="text-right pb-2">
                                {(b.may_steal_from) ? b.may_steal_from.map(r => <p key={r} className="my-0">{r}</p>) : "n/a"}
                            </Col>
                            <Col xs={12} className='border-top pt-2'>
                                <h5>{t("places.capacity")}<span className="float-right"></span></h5>
                            </Col>
                            <Col xs={12} className='pb-2'>
                                {b._id !== "overbooking" ?
                                    <ScreeningFormGeneralField onSubmit={(values) => updateBuckets(values, index)}>
                                        <InputGroup>
                                            <Field component="input" type="number" className="form-control" name={`buckets[${index}].total_capacity`} id="total_capacity" placeholder="totalcap" />
                                            <Button type="submit" size="sm" color="success" disabled={isLoading}>
                                                <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'save'}`}></i>
                                            </Button>
                                        </InputGroup>
                                    </ScreeningFormGeneralField>
                                    :
                                    <span>{t("screenings.edit.form.buckets.bucket_intern")}</span>
                                }
                            </Col>
                            <Col xs={12} className='border-top pt-2'>
                                <h5>{t("screenings.edit.form.buckets.bookings")}</h5>
                            </Col>
                            <Col xs={12}>
                                {b.total_capacity > 0 ?
                                    <FillBar
                                        clickable
                                        bars={[
                                            {
                                                width: b.confirmed * 100 / b.total_capacity,
                                                color: "red",
                                                text: t("screenings.list.confirmed"),
                                                baseInt: b.confirmed
                                            },
                                            {
                                                width: b.unconfirmed * 100 / b.total_capacity,
                                                color: "#A3A300",
                                                text: t("screenings.list.unconfirmed"),
                                                baseInt: b.unconfirmed
                                            },
                                            {
                                                width: b.available * 100 / b.total_capacity,
                                                color: "green",
                                                text: t("screenings.list.available"),
                                                baseInt: b.available
                                            },
                                            {
                                                width: 0,
                                                color: "black",
                                                text: t("screenings.list.total"),
                                                baseInt: b.total_capacity
                                            }
                                        ]}
                                    />
                                    :
                                    "n/a"
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </>
    )
}

export default ScreeningFormBuckets;
