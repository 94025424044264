// Redux Users module
// see https://github.com/erikras/ducks-modular-redux

import { User } from '../../models';
import { Utils } from '../../services';
import Users from '../../services/rest/Users';
import { updateAuthUser } from './auth';
import { createRestSlices, applyReducers } from './rest';

const LOADING_USERS                  = 'kronos/users/LOADING_USERS';
const LOADING_USERS_SUCCESS          = 'kronos/users/LOADING_USERS_SUCCESS';
const LOADING_USERS_FAILURE          = 'kronos/users/LOADING_USERS_FAILURE';
const LOADING_INACTIVE_USERS         = 'kronos/users/LOADING_INACTIVE_USERS';
const LOADING_INACTIVE_USERS_SUCCESS = 'kronos/users/LOADING_INACTIVE_USERS_SUCCESS';
const LOADING_INACTIVE_USERS_FAILURE = 'kronos/users/LOADING_INACTIVE_USERS_FAILURE';
const LOADING_USER                   = 'kronos/users/LOADING_USER';
const LOADING_USER_SUCCESS           = 'kronos/users/LOADING_USER_SUCCESS';
const LOADING_USER_FAILURE           = 'kronos/users/LOADING_USER_FAILURE';
const UPDATING_USER                  = 'kronos/users/UPDATING_USER';
const UPDATING_USER_SUCCESS          = 'kronos/users/UPDATING_USER_SUCCESS';
const UPDATING_USER_FAILURE          = 'kronos/users/UPDATING_USER_FAILURE';
const START_CREATING_USER            = 'kronos/users/START_CREATING_USER';
const CREATING_USER                  = 'kronos/users/CREATING_USER';
const CREATING_USER_SUCCESS          = 'kronos/users/CREATING_USER_SUCCESS';
const CREATING_USER_FAILURE          = 'kronos/users/CREATING_USER_FAILURE';
const DELETING_USER                  = 'kronos/users/DELETING_USER';
const DELETING_USER_SUCCESS          = 'kronos/users/DELETING_USER_SUCCESS';
const DELETING_USER_FAILURE          = 'kronos/users/DELETING_USER_FAILURE';
const DISABLING_USER                 = 'kronos/users/DISABLING_USER';
const DISABLING_USER_FAILURE         = 'kronos/users/DISABLING_USER_FAILURE';
const DISABLING_USER_SUCCESS         = 'kronos/users/DISABLING_USER_SUCCESS';
const ENABLING_USER                  = 'kronos/users/ENABLING_USER';
const ENABLING_USER_FAILURE          = 'kronos/users/ENABLING_USER_FAILURE';
const ENABLING_USER_SUCCESS          = 'kronos/users/ENABLING_USER_SUCCESS';
const APPLY_USER_ABILITIES_TEMPLATE  = 'kronos/users/APPLY_USER_ABILITIES_TEMPLATE';
const ADD_SECTOR_USER                = 'kronos/users/ADD_SECTOR_USER';
const ADD_SECTOR_USER_SUCCESS        = 'kronos/users/ADD_SECTOR_USER_SUCCESS';
const ADD_SECTOR_USER_FAILURE        = 'kronos/users/ADD_SECTOR_USER_FAILURE';
const REMOVE_SECTOR_USER             = 'kronos/users/REMOVE_SECTOR_USER';
const REMOVE_SECTOR_USER_SUCCESS     = 'kronos/users/REMOVE_SECTOR_USER_SUCCESS';
const REMOVE_SECTOR_USER_FAILURE     = 'kronos/users/REMOVE_SECTOR_USER_FAILURE';
const REMOVE_USER_AVATAR             = 'kronos/users/REMOVE_USER_AVATAR';

const APPROVING_APPLICATION_SUCCESS = 'kronos/sectors/APPROVING_APPLICATION_SUCCESS';
const REJECTING_APPLICATION_SUCCESS = 'kronos/sectors/REJECTING_APPLICATION_SUCCESS';

const {
    initialState,
    linkedResourcesReducer, linkedResourcesAction
} = createRestSlices(Users);

initialState.isLoading               = false;
initialState.hasLoadingError         = false;
initialState.loadingErrorMessage     = '';
initialState.userSuccessfullyCreated = false;
initialState.userSuccessfullyUpdated = false;
initialState.user                    = null;
initialState.templateApplied         = false;

// Reducer
const reducer = (state = initialState, action) => {
    switch(action.type) {
        case LOADING_USERS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                userSuccessfullyCreated: false,
                users: action.users
            };
        case LOADING_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_INACTIVE_USERS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_INACTIVE_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                inactive_users: action.users
            };
        case LOADING_INACTIVE_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                userSuccessfullyCreated: false,
                user: action.user
            };
        case LOADING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case ADD_SECTOR_USER:
        case REMOVE_SECTOR_USER:
        case UPDATING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                userSuccessfullyUpdated: false,
            };
        case UPDATING_USER_SUCCESS:
        case ADD_SECTOR_USER_SUCCESS:
        case REMOVE_SECTOR_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                user: action.user,
                userSuccessfullyUpdated: true,
                templateApplied: false
            };
        case UPDATING_USER_FAILURE:
        case ADD_SECTOR_USER_FAILURE:
        case REMOVE_SECTOR_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
                userSuccessfullyUpdated: false
            };
        case START_CREATING_USER:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                userSuccessfullyCreated: false,
                user: null
            };
        case CREATING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                userSuccessfullyCreated: true,
                user: action.user
            };
        case CREATING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                userSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                userSuccessfullyDeleted: true,
                user: action.user
            };
        case DELETING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                userSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case DISABLING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DISABLING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                user: action.user
            };
        case DISABLING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case ENABLING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case ENABLING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                user: action.user
            };
        case ENABLING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case APPLY_USER_ABILITIES_TEMPLATE:
            return {
                ...state,
                user: new User({
                    ...state.user,
                    abilities : action.abilities
                }),
                templateApplied: true
            };
        case REMOVE_USER_AVATAR:
            return {
                ...state,
                user: new User({
                    ...state.user,
                    avatar : null
                }),
            };
        case APPROVING_APPLICATION_SUCCESS:
            if (!state.user)
                return state;
            return {
                ...state,
                user: new User({
                    ...state.user,
                    applications: (state.user.applications || []).map(a => {
                        if (a.id === action.application.id)
                            return action.application;
                        return a;
                    })
                })
            };
        case REJECTING_APPLICATION_SUCCESS:
            if (!state.user)
                return state;
            return {
                ...state,
                user: new User({
                    ...state.user,
                    applications: (state.user.applications || []).map(a => {
                        if (a.id === action.application.id)
                            return action.application;
                        return a;
                    })
                })
            };
        default:
            return state;
    }
};
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        linkedResourcesReducer, reducer
    ]);
}

// Actions
function loadingUsers() { return { type: LOADING_USERS } }
function loadingUsersSuccess(users) { return { type: LOADING_USERS_SUCCESS, users: users } }
function loadingUsersFailure(err) { return { type: LOADING_USERS_FAILURE, error: err } }
export function loadUsers() {
    return (dispatch) => {
        dispatch(loadingUsers());
        Users.list()
            .then(data => {
                const users = data.users;
                dispatch(loadingUsersSuccess(users));
            })
            .catch(err => {
                dispatch(loadingUsersFailure(err))
            });
    }
}

function loadingInactiveUsers() { return { type: LOADING_INACTIVE_USERS } }
function loadingInactiveUsersSuccess(users) { return { type: LOADING_INACTIVE_USERS_SUCCESS, users: users } }
function loadingInactiveUsersFailure(err) { return { type: LOADING_INACTIVE_USERS_FAILURE, error: err } }
export function loadInactiveUsers() {
    return (dispatch) => {
        dispatch(loadingInactiveUsers());
        Users.inactive()
            .then(data => {
                const users = data.users;
                dispatch(loadingInactiveUsersSuccess(users));
            })
            .catch(err => {
                dispatch(loadingInactiveUsersFailure(err))
            });
    }
}

function loadingUser() { return { type: LOADING_USER } }
function loadingUserSuccess(user) { return { type: LOADING_USER_SUCCESS, user: user } }
function loadingUserFailure(err) { return { type: LOADING_USER_FAILURE, error: err } }
export function loadUser(userId) {
    return (dispatch) => {
        dispatch(loadingUser());
        Users.get(userId)
            .then(data => {
                const user = data.user;
                dispatch(loadingUserSuccess(user));

            })
            .catch(err => {
                dispatch(loadingUserFailure(err))
            });
    }
}

function updatingUser() { return { type: UPDATING_USER } }
function updatingUserSuccess(user) { return { type: UPDATING_USER_SUCCESS, user: user } }
function updatingUserFailure(err) { return { type: UPDATING_USER_FAILURE, error: err } }
export function updateUser(user) {
    return (dispatch) => {
        dispatch(updatingUser());
        Users.update(user)
            .then(data => {
                const user = data.user;
                dispatch(updatingUserSuccess(user));
                dispatch(updateAuthUser(user));
            })
            .catch(err => {
                dispatch(updatingUserFailure(err))
            });
    }
}

export function applyUserAbilitiesTemplate(abilities) {
    return (dispatch) => {
        return dispatch({ type: APPLY_USER_ABILITIES_TEMPLATE, abilities });
    }
}


function creatingUser() { return { type: CREATING_USER } }
function creatingUserSuccess(user) { return { type: CREATING_USER_SUCCESS, user: user } }
function creatingUserFailure(err) { return { type: CREATING_USER_FAILURE, error: err } }
export function startCreateUser() { return { type: START_CREATING_USER } }
export function createUser(user) {
    return (dispatch) => {
        dispatch(creatingUser());
        Users.create(user)
            .then(data => {
                const user = data.user;
                dispatch(creatingUserSuccess(user));
                dispatch(loadingUserSuccess(user));
            })
            .catch(err => {
                dispatch(creatingUserFailure(err))
            });
    }
}

function deletingUser() { return { type: DELETING_USER } }
function deletingUserSuccess() { return { type: DELETING_USER_SUCCESS } }
function deletingUserFailure(err) { return { type: DELETING_USER_FAILURE, error: err } }
export function deleteUser(userId) {
    return (dispatch) => {
        dispatch(deletingUser());
        Users.hide(userId)
            .then(data => {
                dispatch(deletingUserSuccess());
            })
            .catch(err => {
                dispatch(deletingUserFailure(err))
            });
    }
}

function disablingUser() { return { type: DISABLING_USER } }
function disablingUserSuccess(user) { return { type: DISABLING_USER_SUCCESS, user: user } }
function disablingUserFailure(err) { return { type: DISABLING_USER_FAILURE, error: err } }
function enablingUser() { return { type: ENABLING_USER } }
function enablingUserSuccess(user) { return { type: ENABLING_USER_SUCCESS, user: user } }
function enablingUserFailure(err) { return { type: ENABLING_USER_FAILURE, error: err } }
export function toggleUserStatus(user) {
    return (dispatch) => {
        if (user.is_active) {
            dispatch(disablingUser());
            Users.disable(user.id)
                .then(data => {
                    dispatch(disablingUserSuccess(data.user));
                })
                .catch(err => {
                    dispatch(disablingUserFailure(err));
                });
        } else {
            dispatch(enablingUser());
            Users.enable(user.id)
                .then(data => {
                    dispatch(enablingUserSuccess(data.user));
                })
                .catch(err => {
                    dispatch(enablingUserFailure(err));
                });
        }
    }
}

function addSector() { return { type: ADD_SECTOR_USER } }
function addSectorSuccess(user) { return { type: ADD_SECTOR_USER_SUCCESS, user } }
function addSectorFailure(err) { return { type: ADD_SECTOR_USER_FAILURE, error: err } }
export function addSectorUser(params) {
    return (dispatch) => {
        dispatch(addSector());
        Users.addSector(params)
            .then(data => {
                dispatch(addSectorSuccess(data.user));
            })
            .catch(err => {
                dispatch(addSectorFailure(err))
            });
    }
}

function removeSector() { return { type: ADD_SECTOR_USER } }
function removeSectorSuccess(user) { return { type: ADD_SECTOR_USER_SUCCESS, user } }
function removeSectorFailure(err) { return { type: ADD_SECTOR_USER_FAILURE, error: err } }
export function removeSectorUser(params) {
    return (dispatch) => {
        dispatch(removeSector());
        Users.removeSector(params)
            .then(data => {
                dispatch(removeSectorSuccess(data.user));
            })
            .catch(err => {
                dispatch(removeSectorFailure(err))
            });
    }
}

export function removeUserAvatar(params) {
    return (dispatch) => dispatch({ type: REMOVE_USER_AVATAR });
}

export const getUsersLinkedResources = linkedResourcesAction;
