import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Shift, Task } from '../../../models';
import { loadShift, updateShift, deleteShift, assignTask, changeTaskStatus } from '../../../redux';
import { Loader, DateTimePicker, SectorField, ActivitiesField } from '../../../components';
import classnames from 'classnames';

import moment from 'moment';
import user_avatar from '../../../assets/images/user_avatar.jpg';

import './ShiftForm.css';

let ShiftForm = (props) => {
    const { sectors, activities, onRemove } = props;
    const { isCreation, handleSubmit, pristine, submitting } = props;

    const { t, i18n } = useTranslation();
    const dispatch    = useDispatch();

    const [activeTab, setActiveTab]                       = useState('available');
    const [confirmationIsVisible, setConfirmationVisible] = useState(false);

    const { isLoadingOne, shift } = useSelector(state => state.shifts);

    const suggestions = React.useMemo(() => (shift?.suggestions || []).filter(
        suggestion => !!suggestion.in_sector && !suggestion.overbooked
    ), [ shift ]);

    const outOfSectorSuggestions = React.useMemo(() => (shift?.suggestions || []).filter(
        suggestion => !suggestion.in_sector && !suggestion.overbooked
    ), [ shift ]);

    const overbookedSuggestions = React.useMemo(() => (shift?.suggestions || []).filter(
        suggestion => !!suggestion.overbooked
    ), [ shift ]);

    useEffect(() => {
        if (suggestions?.length)
            setActiveTab('available');
        else if (outOfSectorSuggestions?.length)
            setActiveTab('out_of_sector');
        else if (overbookedSuggestions?.length)
            setActiveTab('overbooked');
    }, [ shift ]);

    const toggle = tab => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const remove = () => {
        dispatch(deleteShift(shift?.id, (err) => {
            onRemove && onRemove(err);
        }));
    };

    const removeVolunteer = task_id => {
        dispatch(changeTaskStatus(task_id, 'free', (err, task) => {
            dispatch(loadShift(shift?.id));
        }));
    };

    const addVolunteer = user_id => {
        const assignable = shift?.tasks.find(task => !task.user_id);
        if (assignable)
            dispatch(assignTask(assignable.id, user_id, (err, task) => {
                dispatch(loadShift(shift?.id));
            }));
    };

    const renderSuggestions = (suggestions, assignable) => {
        return suggestions?.map(suggestion => (
            <Row key={ suggestion.id } className="my-1">
                <Col sm={12}>
                    <div className="volunteer_row" sm={12}>
                        <div className="img_wrapper">
                            <img src={ suggestion.avatar || user_avatar } />
                        </div>
                        <div className="infos_wrapper">
                            <div className="name">
                                <Link target="_blank" to={`/users/edit/${ suggestion.id }#team`}>
                                    { suggestion.fullname }
                                </Link>
                            </div>
                        </div>
                        {assignable && (
                            <div className="action">
                                <Button color="success" onClick={() => addVolunteer(suggestion.id) }>
                                    <i className="fa fa-plus" />
                                </Button>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        ));
    };

    return (
        <Form className="ShiftForm" onSubmit={handleSubmit}>
            <Row className="my-0">
                <Col>
                    <Card>
                        <CardBody>
                            {isLoadingOne && (
                                <div className="loader-wrapper">
                                    <Loader />
                                </div>
                            )}
                            <CardTitle>
                                <i className="fa fa-map-marker"></i>&nbsp;
                                {t("shifts.sector")}
                            </CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <SectorField name="sector_id" sectors={ sectors } single />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                            <CardTitle>
                                <i className="fa fa-calendar"></i>&nbsp;
                                {t("shifts.dates")}
                            </CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col className="start-at-wrapper">
                                        <FormGroup row>
                                            <Label for="start_at" sm={12}>{t("shifts.start_at")}</Label>
                                            <Col sm={12}>
                                                <Field
                                                    component={DateTimePicker}
                                                    type="text"
                                                    name="start_at"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col className="stop-at-wrapper">
                                        <FormGroup row>
                                            <Label for="stop_at" sm={12}>{t("shifts.stop_at")}</Label>
                                            <Col sm={12}>
                                                <Field
                                                    component={DateTimePicker}
                                                    type="text"
                                                    name="stop_at"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                            <CardTitle>
                                <i className="fa fa-badge-id"></i>&nbsp;
                                {t("shifts.needs")}
                            </CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="nb_volunteers" sm={12}>{t("shifts.nb_volunteers")}</Label>
                                            <Col sm={12}>
                                                <Field
                                                    component="input"
                                                    type="number"
                                                    name="nb_volunteers"
                                                    className="form-control"
                                                    min={ 0 }
                                                    max={ 100 }
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                            {activities?.length > 1 && (
                                <>
                                    <CardTitle>
                                        <i className="fa fa-badge-id"></i>&nbsp;
                                        {t("shifts.activity")}
                                    </CardTitle>
                                    <CardText tag="div">
                                        <Row>
                                            <Col>
                                                <FormGroup row>
                                                    <Col sm={12}>
                                                        <ActivitiesField single name="activity_id" activities={ activities || [] } single />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </>
                            )}

                            <CardTitle>
                                <i className="fa fa-badge-id"></i>&nbsp;
                                {t("shifts.status")}
                            </CardTitle>
                            <CardText tag="div">
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Field component="select" className="form-control" name="status" id="status">
                                            <option value={Task.STATUS_NEW}>{ t('shifts.status_new') }</option>)
                                            <option value={Task.STATUS_FREE}>{ t('shifts.status_free') }</option>)
                                        </Field>
                                    </Col>
                                </FormGroup>
                            </CardText>

                            <Row>
                                <Col>
                                    <ButtonGroup>
                                        <Button type="submit" color="success" disabled={pristine || submitting || isLoadingOne}>
                                            {isLoadingOne ?
                                                <span>
                                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                </span>
                                                :
                                                <span>
                                                    <i className="fa fa-save"></i>&nbsp;
                                                    {t("common.save")}
                                                </span>
                                            }
                                        </Button>
                                        {confirmationIsVisible ? (
                                            <div className="remove-shift-wrapper">
                                                <b>{t('common.are_you_sure')}</b>&nbsp;
                                                <ButtonGroup>
                                                    <Button onClick={() => setConfirmationVisible(false)} color='info' size="sm">
                                                        <i className="fa fa-times"></i> {t("common.no")}
                                                    </Button>
                                                    <Button color="danger" onClick={ remove }>
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;{t("common.yes")}
                                                        </span>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        ) : (
                                            <Button color="danger" onClick={() => setConfirmationVisible(true)}>
                                                <span>
                                                    <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                                </span>
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            )}

                            {(shift?.nb_assigned > 0) && (
                            <>
                                <CardTitle>
                                    <i className="fa fa-users"></i>&nbsp;
                                    {t("shifts.assigned")} - { shift.nb_assigned } / { shift.nb_volunteers }
                                </CardTitle>
                                <CardText tag="div">
                                    {shift.tasks?.filter(t => !!t.user_id).sort((a, b) => a.user.fullname.localeCompare(b.user.fullname)).map(task => (
                                        <Row key={ task.id } className="my-1">
                                            <Col sm={12}>
                                                <div className="volunteer_row" sm={12}>
                                                    <div className="img_wrapper">
                                                        <img src={task.user.avatar || user_avatar} />
                                                    </div>
                                                    <div className="infos_wrapper">
                                                        <div className="name">
                                                            <Link target="_blank" to={`/users/edit/${ task.user.id }#team`}>
                                                                { task.user.fullname }
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="action">
                                                        <Button color="danger" disabled={task.status === Task.STATUS_CLAIMED} onClick={() => removeVolunteer(task.id) }>
                                                            <i className="fa fa-times" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </CardText>
                            </>
                            )}
                            {(shift?.nb_assigned < shift?.tasks?.length) && (
                            <>
                                <CardTitle>
                                    <i className="fa fa-users"></i>&nbsp;
                                    {t("shifts.suggestions")}
                                </CardTitle>
                                <CardText tag="div">
                                    {(shift.suggestions.length === 0) ? (
                                    <span>
                                        { t('shifts.no_suggestions_found') }
                                    </span>
                                    ) : (
                                        <>
                                            <Nav tabs justified className="suggestions-tabs">
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 'available' })}
                                                        onClick={() => { toggle('available'); }}
                                                    >
                                                        {t('shifts.available')} ({ suggestions.length })
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 'out_of_sector' })}
                                                        onClick={() => { toggle('out_of_sector'); }}
                                                    >
                                                        {t('shifts.out_of_sector')} ({ outOfSectorSuggestions.length })
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 'overbooked' })}
                                                        onClick={() => { toggle('overbooked'); }}
                                                    >
                                                        {t('shifts.overbooked')} ({ overbookedSuggestions.length })
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="available">
                                                    { renderSuggestions(suggestions, /*assignable*/true) }
                                                </TabPane>
                                                <TabPane tabId="out_of_sector">
                                                    { renderSuggestions(outOfSectorSuggestions, /*assignable*/true) }
                                                </TabPane>
                                                <TabPane tabId="overbooked">
                                                    { renderSuggestions(overbookedSuggestions, /*assignable*/false) }
                                                </TabPane>
                                            </TabContent>
                                        </>
                                    )}
                                </CardText>
                            </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Form>
    );

};

ShiftForm = reduxForm({
  form: 'shiftForm',
  enableReinitialize: true
})(ShiftForm);

ShiftForm = connect(
  state => ({
    initialValues: state.shifts.shift,
    Shifts: state.shifts,
}), {}
)(ShiftForm)

export default ShiftForm;
