import React, { useState, useEffect, useMemo } from 'react';
import { Badge, Row, Col, Button, ButtonGroup } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import _ from 'lodash';
import { arrayMoveMutable } from 'array-move';
import PricingForm from './../PricingForm';
import DraggableComponent from '../Draggable';
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import { Utils } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { setSmallDraggableCards, loadTags } from '../../redux';

import './PricingsForm.css';

const PricingsForm = ({ pricings, remove, updatePricingOrder, isLoading, changeId }) => {
    const dispatch = useDispatch();
    const { t }                   = useTranslation()
    const [collapse, setCollapse] = useState([]);
    const currency                = Utils.currency();

    const { KUserSettings } = useSelector(state => state.userSettings);
    const smallCards        = !!KUserSettings.smallDraggableCards;

    useEffect(() => {
        dispatch(loadTags());
    }, [dispatch]);

    const onCollapse = (id) => {
        let newValues = collapse;
        newValues[id] = !newValues[id];
        setCollapse(newValues);
    }

    const setUseSmallCards = useSmallCards => {
        dispatch(setSmallDraggableCards(!!useSmallCards));
    };

    const removePricingAtKey = (key, id) => {
        let temp = _.cloneDeep(collapse)
        temp.splice(id, 1);
        setCollapse(temp);
        remove(key);
    }

    const sortedPricings = useMemo(() => {
        return _.sortBy(_.map(pricings, (pricing, key) => { return { ...pricing, key }; }), 'opaque.eshop_sort_weight')
    }, [pricings])

    useEffect(() => {
        setCollapse(Object.keys(pricings).map(p => { return false }));
    }, []);

    const buildPreview = pricingsArray => {
        const DeleteActions = (key, id) => {
            return (
                <Button color="danger" size="sm" onClick={() => { removePricingAtKey(key, id); }}>
                    <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                </Button>
            );
        };

        const SortableTab = SortableElement(({ pricing, idx, sortIndex }) => {
            return (
                <Col sm={smallCards ? 6 : 12} lg={smallCards ? 3 : 6} key={pricing.key} className="mb-4" >
                    <DraggableComponent
                        id={idx}
                        onCollapse={onCollapse}
                        collapsed={collapse[idx]}
                        cardbody={
                            <PricingForm
                                pricing={pricing}
                                smallCard={!smallCards}
                                isLoading={isLoading}
                                changeId={changeId}
                                pricingId={pricing.key}
                                pricingKeys={Object.keys(pricings)}
                            />
                        }
                        title={
                            <>
                                <div>
                                    <b>{pricing.name[i18n.language] ?? pricing.name[Object.keys(pricing.name)[0]] ?? ""}</b>
                                </div>
                                <i>
                                    <small>{currency + " " + pricing.price[currency]}</small> /{' '}
                                    <small>{currency + " " + pricing.value[currency]}</small>
                                </i>
                            </>
                        }
                        badge={pricing.key}
                        actions={DeleteActions(pricing.key, idx)}
                        tags={ pricing?.tags }
                    />
                </Col>)
        });

        const SortableGrid = SortableContainer(({ pricingsArray }) => {
            return (
                <Row className="sortable-grid">
                    {(pricingsArray || []).map((pricing, index) => {
                        return <SortableTab key={`item-${index}`} index={index} idx={index} pricing={pricing} sortIndex={pricing.opaque.eshop_sort_weight} />;
                    })}
                </Row>
            );
        });

        const onSortEnd = ({ oldIndex, newIndex }) => {
            let pricings = [...sortedPricings];
            let newCollapse = collapse;
            arrayMoveMutable(newCollapse, oldIndex, newIndex);
            arrayMoveMutable(pricings, oldIndex, newIndex);
            pricings = pricings.map((p, i) => {
                p.opaque.eshop_sort_weight = i;
                return p;
            });
            updatePricingOrder(pricings);
            setCollapse(newCollapse);
        };

        return <SortableGrid
            pricingsArray={pricingsArray}
            axis="xy"
            onSortEnd={onSortEnd}
            useDragHandle={true}
            useWindowAsScrollContainer
            helperClass="sortableHelper"
        />
    };

    const pricingComponent = useMemo(() => {
        return buildPreview(sortedPricings)
    }, [sortedPricings, smallCards])

    return (
        <div className="PricingsForm">
            <Row className='px-3'>
                <Col>
                    <ButtonGroup className="float-right">
                        <Button style={{height: "33.5px"}} color={smallCards ? 'primary' : 'dark'} onClick={() => setUseSmallCards(true)}>
                            <i className='fa fa-th'></i>
                        </Button>
                        <Button style={{height: "33.5px"}} color={!smallCards ? 'primary' : 'dark'} onClick={() => setUseSmallCards(false)}>
                            <i className='fa fa-th-large'></i>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {sortedPricings.length > 0 &&
                pricingComponent
            }
        </div>
    );
}

export default PricingsForm;
