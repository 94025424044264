import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Label,
    ButtonGroup,
    Button,
    Card,
    CardBody,
    CardTitle,
    CardText,
} from 'reactstrap';
import { ChromePicker } from 'react-color';
import { Field } from 'redux-form';
import { EmailLayout } from '../../../../../models';
import { ColorPickerField, TranslatableField } from '../../../../../components';

const EmailLayoutOptions = ({ onSubmit }) => {
    const { t } = useTranslation();

    const { isMobile } = useSelector(state => state.ui);
    const { isLoadingOne } = useSelector(state => state.layouts);

    return (
        <div className="EmailLayoutOptions">
            <Card className="h-auto mb-2">
                <CardBody>
                    <CardTitle className="small">{t('layouts.general_options')}</CardTitle>
                    <CardText tag="div" className={isMobile ? "mb-4 d-flex flex-column-reverse" : "mb-4"}>
                        <Row>
                            <Col>
                                <Label>{t('layouts.layout.name')}</Label>
                                <TranslatableField  data-for='layouts.name' required component="input" className="form-control" type="text" name="name" placeholder={t("layouts.layout.name_placeholder")} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>{t('layouts.layout.subject')}</Label>
                                <TranslatableField  data-for='layouts.subject' required component="input" className="form-control" type="text" name="subject" placeholder={t("layouts.layout.subject_placeholder")} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>{t('layouts.layout.options.bcc')}</Label>
                                <Field  data-for='layouts.options.bcc' component="input" className="form-control" type="email" name="options[bcc]" placeholder={t("layouts.layout.options.bcc_placeholder")} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>{t('layouts.layout.options.replyTo')}</Label>
                                <Field  data-for='layouts.options.replyTo' component="input" className="form-control" type="email" name="options[replyTo]" placeholder={t("layouts.layout.options.replyTo_placeholder")} />
                            </Col>
                        </Row>
                    </CardText>
                </CardBody>
            </Card>
            <Card className="h-auto mb-2">
                <CardBody>
                    <CardTitle className="small">{t('layouts.style_options')}</CardTitle>
                    <CardText tag="div" className={isMobile ? "mb-4 d-flex flex-column-reverse" : "mb-4"}>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Label> {t("layouts.background_color")}</Label>
                                <ColorPickerField name="options.style.backgroundColor" />
                            </Col>
                            <Col sm={12} lg={6}>
                                <Label> {t("layouts.border_color")}</Label>
                                <ColorPickerField name="options.style.borderColor" />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={12} lg={4}>
                              <Label>{t('layouts.border_width')}</Label>
                              <Field component="input" type="number" className="form-control" min = { 0 } name="options.style.borderWidth" />
                            </Col>
                            <Col sm={12} lg={4}>
                                <Label> {t("layouts.border_style")}</Label>
                                <Field component="select" className="form-control" name="options.style.borderStyle" >
                                    <option>{t("layouts.border_style_placeholder")}</option>
                                    {EmailLayout.borderStyleValues().map(value => (
                                        <option key={ value } value={ value }>
                                            { t(`layouts.border_style_${value}`) }
                                        </option>
                                    ))}
                                </Field>
                            </Col>
                            <Col sm={12} lg={4}>
                              <Label>{t('layouts.border_radius')}</Label>
                              <Field component="input" type="number" className="form-control" min = { 0 } name="options.style.borderRadius" />
                            </Col>
                        </Row>

                        <div className="d-flex">
                            <Button onClick={onSubmit} type="submit" color="success" disabled={isLoadingOne}>
                                {isLoadingOne ?
                                    <span><i className="fa fa-spinner fa-spin"></i></span>
                                    :
                                    <span><i className="fa fa-save"></i>&nbsp; {t("layouts.apply_layout_style")} </span>
                                }
                            </Button>
                        </div>
                    </CardText>
                </CardBody>
            </Card>
        </div>
    );
}

export default EmailLayoutOptions;
