import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Row, Col, FormGroup, Label, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import ToggleField from '../../ToggleField/ToggleField';

import logo from '../../../assets/images/integrations/kronos.png';

const KronosImportFields = () => {
    const { t }                  = useTranslation();
    const { isMobile, isTablet } = useSelector(state => state.ui);

    return (
        <div className="KronosImportFields">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="small">
                                <img src={logo} className="img rounded" />
                                { t('settings.integrations.kronos.title') }
                            </CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                    <FormGroup row>
                                        <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.kronos.api_key")}</Label>
                                            <Col lg={10} >
                                                <Field required component="input" className="form-control" type="text" name="integrations.kronos.api_key" id="api_key" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>

                        <CardBody>
                            <CardTitle className="small">{ t('settings.integrations.kronos.import.title') }</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label lg={3} className={ !isMobile && !isTablet ? "text-right" : ""}>{t("settings.integrations.kronos.import.screenings.title")}</Label>
                                            <Col lg={1} >
                                                <ToggleField name="integrations.kronos.import.screenings.enabled" />
                                            </Col>
                                            <Label lg={3} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.kronos.import.screenings.delete_future")}</Label>
                                            <Col lg={1} >
                                            <ToggleField name="integrations.kronos.import.screenings.delete_future" />
                                            </Col>
                                            <Label lg={3} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.kronos.import.screenings.delete_existing")}</Label>
                                            <Col lg={1} >
                                            <ToggleField name="integrations.kronos.import.screenings.delete_existing" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default KronosImportFields;
