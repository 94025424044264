import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText, Input } from 'reactstrap';
import { Form, FormGroup, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import { Utils } from '../../../services';
import {
    PdfLayoutMappings, EmailLayoutMappings, PricingsField,
    ToggleField, TranslatableField,
    PricingsForm, WindowsForm, FieldsForm,
    WysiwygField, SalepointsField, HelpTooltips, SelectField,
} from '../../../components';
import {
    addPricing, removePricing, addWindow, removeWindow,
    addFields, removeFields, updateTickettypePricings,
    updatePricingsKeys, addLayout, removeLayout
} from '../../../redux/modules/tickettypes';
import { loadSalepoints, loadPricinglists, loadTickettypesRefs } from '../../../redux';
import './TickettypeForm.css';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import { PdfLayout, Tickettype } from '../../../models';
import 'react-widgets/dist/css/react-widgets.css'
import i18n from '../../../locales/i18n';

moment.locale('fr');
momentLocalizer();

let TickettypeForm = ({ isCreation, handleSubmit, submitting }) => {

    const { t }                                               = useTranslation();
    const dispatch                                            = useDispatch();
    const { tickettype, isLoading }                           = useSelector(state => state.tickettypes);
    const { salepoints }                                      = useSelector(state => state.salepoints);
    const { pricinglists }                                    = useSelector(state => state.pricinglists);
    const [pricingLayoutId, setPricingLayoutId]               = useState();
    const [activeTab, setActiveTab]                           = useState('pricings');
    const [selectedSalepointIds, setSalepointIds]             = useState(null);
    const [tickettypeLayouts, setTickettypeLayouts]           = useState([])
    const [disabledBtnAddTemplate, setDisabledBtnAddTemplate] = useState(true);
    const [showHelpRenewable, setShowHelpRenewable]           = useState(false);
    const [isRenewable, setIsRenewable]                       = useState(tickettype?.renewable)

    useEffect(() => {
        if (tickettype?.opaque?.layouts?.overrides)
            setTickettypeLayouts(Object.keys(tickettype?.opaque?.layouts?.overrides))
    }, [tickettype])

    useEffect(() => {
        dispatch(loadSalepoints());
        dispatch(loadPricinglists());
        dispatch(loadTickettypesRefs());
    }, [dispatch]);

    const isOneTimePass = "one-time-pass" === tickettype?._id;

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const _addPricing = () => {
        let names = {}
        Utils.langs().map(l => names[l] = t("pricinglists.new_pricing", { lng: l }))
        dispatch(addPricing(names, Utils.currency()));
    }

    const _removePricing = (_id) => {
        dispatch(removePricing(_id));
    }

    const updatePricingOrder = (pricings) => {
        const newPricings = _.cloneDeep(tickettype?.pricings);
        pricings.forEach((pricing, index) => {
            newPricings[pricing.key].opaque.eshop_sort_weight = index;
        });
        dispatch(updateTickettypePricings(newPricings));
    }

    const _addWindow = () => {
        dispatch(addWindow());
    }

    const _removeWindow = (idx) => {
        dispatch(removeWindow(idx));
    }

    const getAvailableSalepoints = () => {
        return salepoints.filter((item) => !Object.keys((tickettype?.opaque?.fields || {})).some(salepoint => item._id == salepoint));
    }

    const _addFields = () => {
        selectedSalepointIds.map(id => dispatch(addFields(id)));
        setSalepointIds(null);
    }

    const _removeFields = (idx) => {
        dispatch(removeFields(idx));
    }

    const onSalepointsChange = (value) => {
        setSalepointIds(value);
    }

    const changeId = (values) => {
        dispatch(updatePricingsKeys(values))
    }

    const onChangeRenewable = (e) => {
        setIsRenewable(e);
        setShowHelpRenewable(!e);
    }

    const showPricingsForm = React.useMemo(() => {
        return <PricingsForm
            pricings={(tickettype || {}).pricings}
            isLoading={isLoading}
            remove={_removePricing}
            updatePricingOrder={updatePricingOrder}
            changeId={changeId}
        />;
    }, [tickettype]);

    const pricings_for_field = useMemo(() => {
        const temp = [];
        if (tickettypeLayouts && Object.keys(tickettype || {}).length > 0) {
            Object.keys(tickettype?.pricings).map(tt => {
                if (!tickettypeLayouts.find(ttl => tt === ttl))
                    temp.push({
                        id: tt,
                        name: tickettype.pricings[tt].name
                    });
            })
        }
        return temp;
    }, [tickettype, tickettypeLayouts])

    const changeLayoutPricing = (id) => {
        setPricingLayoutId(id);
        if (id.length > 0) {
            setDisabledBtnAddTemplate(false)
        } else {
            setDisabledBtnAddTemplate(true)
        }
    }

    const addLayoutPricing = () => {
        dispatch(addLayout(pricingLayoutId))
        setDisabledBtnAddTemplate(true)
    }

    const removePricingLayout = (id) => {
        dispatch(removeLayout(id));
    }
    const changePricing = (id) => {
        if (id.length > 0) {
            setDisabledBtnAddTemplate(false)
            setPricingLayoutId(id);
        } else {
            setDisabledBtnAddTemplate(true)
        }
    }

    // Information table on the different types of renewal
    const renewTypeInformations = [
        [
            (t("tickettypes.renewTypeInformation.if_user_renews"))+" :",
            (t("tickettypes.renewTypeInformation.now_key")),
            (t("tickettypes.renewTypeInformation.expiration_key")),
            (t("tickettypes.renewTypeInformation.start-of-year_key")),
            (t("tickettypes.renewTypeInformation.latest_key")),
            (t("tickettypes.renewTypeInformation.soonest_key"))
        ],
        [
            (t("tickettypes.renewTypeInformation.before_expiration_date")),
            (t("tickettypes.renewTypeInformation.now")),
            (t("tickettypes.renewTypeInformation.expiration_date")),
            (t("tickettypes.renewTypeInformation.start-of-year_key")),
            (t("tickettypes.renewTypeInformation.at_expiration_date")),
            (t("tickettypes.renewTypeInformation.now"))
        ],
        [
            (t("tickettypes.renewTypeInformation.at_expiration_date")),
            (t("tickettypes.renewTypeInformation.now")),
            (t("tickettypes.renewTypeInformation.expiration_date")),
            (t("tickettypes.renewTypeInformation.start-of-year_key")),
            (t("tickettypes.renewTypeInformation.at_expiration_date")),
            (t("tickettypes.renewTypeInformation.expiration_date"))
        ],
        [
            (t("tickettypes.renewTypeInformation.after_expiration_date")),
            (t("tickettypes.renewTypeInformation.now")),
            (t("tickettypes.renewTypeInformation.expiration_date")),
            (t("tickettypes.renewTypeInformation.start_of_current_year")),
            (t("tickettypes.renewTypeInformation.now")),
            (t("tickettypes.renewTypeInformation.at_expiration_date"))
        ]
    ];

    const hideOrshowhelRenewable = () => {
        setShowHelpRenewable(!showHelpRenewable);
    };

    const renewableType  = Tickettype.renewTypes().map(k => ({
        key: k,
        text: t("tickettypes.renewTypeInformation." + k + "_key"),
        value: k
    }));

    return (
        <Form className="TickettypeForm" onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("tickettypes.informations")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row>
                                            <Label for="name" sm={4}>{t('tickettypes.name')}</Label>
                                            <Col sm={8}>
                                                <TranslatableField data-tip data-for='nameTip' required component="input" className="form-control" type="text" name="name" id="name" autoComplete="name" placeholder={t("tickettypes.name_placeholder")} />
                                                <HelpTooltips id='nameTip' value='name' />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="description" sm={2}>{t('tickettypes.description')}</Label>
                                            <Col sm={10}>
                                                <TranslatableField data-tip data-for='descriptionTip' component={WysiwygField} className="form-control" type="text" name="description" id="description" autoComplete="description" placeholder={t("tickettypes.description_placeholder")} optional />
                                                <HelpTooltips id='descriptionTip' value='description' />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row>
                                            <Label for="_id" sm={4} className="text-right" >{t("tickettypes._id")}</Label>
                                            <Col sm={8} data-tip data-for='tktIdTip'>
                                                <Field
                                                    required
                                                    component="input"
                                                    className="form-control"
                                                    type="text"
                                                    name="_id"
                                                    id="_id"
                                                    autoComplete="_id"
                                                    disabled={!isCreation}
                                                    placeholder={t("tickettypes._id")}
                                                    onInvalid={e => e.target.setCustomValidity(t("common.invalid_id_input"))}
                                                    onInput={e => e.target.setCustomValidity(t(""))}
                                                    pattern={isCreation ? "[A-Za-z0-9_]+" : ".+"}
                                                />
                                            </Col>
                                            <HelpTooltips id='tktIdTip' value='tkt_id' />
                                        </FormGroup>
                                        {!isOneTimePass &&
                                            <FormGroup row>
                                                <Label for="renewable" className="text-right" sm={4}>{t("tickettypes.renewable")}</Label>
                                                <Col sm={8} className="text-left">
                                                    <div className="d-flex justify-content-md-between">
                                                        <ToggleField name="renewable" onSelectChange={onChangeRenewable} />
                                                        {isRenewable &&
                                                            <Button className="" color="info mb-3" onClick={hideOrshowhelRenewable} >
                                                                <i className="fa fa-info-circle mr-2"></i>
                                                                {isRenewable && !showHelpRenewable ? t('tickettypes.renewTypeInformation.help_text_show') : t('tickettypes.renewTypeInformation.help_text_hide') }
                                                            </Button>
                                                        }
                                                    </div>
                                                    {isRenewable &&
                                                        <SelectField
                                                            name="renew_type"
                                                            options={renewableType}
                                                            placeholder={ t('tickettypes.renewTypeInformation.choose_renew_type') }
                                                            clearable
                                                        />
                                                    }
                                                </Col>
                                            </FormGroup>
                                        }
                                    </Col>
                                </Row>
                                    {isRenewable && showHelpRenewable &&
                                    <>
                                        <p className="text-white">{t('tickettypes.renewTypeInformation.help_description')}</p>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    {renewTypeInformations && renewTypeInformations[0].map((item, index) => {
                                                        return <th className='text-white' key={index}>{item}</th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renewTypeInformations && renewTypeInformations.map((informations, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {index > 0 && informations.map((item, i) => {
                                                                return <td className={`text-white ${i === 0 ? 'font-weight-bold' : ''}`} key={i}>{item}</td>
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </>
                                    }
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {!isCreation && (
                <>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === 'pricings' })} onClick={() => { toggle('pricings'); }}>
                                {t('tickettypes.pricings')}
                            </NavLink>
                        </NavItem>
                        {!isOneTimePass &&
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === 'windows' })} onClick={() => { toggle('windows'); }}>
                                {t('tickettypes.windows')}
                            </NavLink>
                        </NavItem>
                        }
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === 'fields' })} onClick={() => { toggle('fields'); }}>
                                {t('tickettypes.fields')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === 'pdflayouts' })} onClick={() => { toggle('pdflayouts'); }}>
                                {t('tickettypes.pdflayouts')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === 'emails' })} onClick={() => { toggle('emails'); }}>
                                {t('tickettypes.emails')}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="pricings">
                            {isOneTimePass &&
                                <h5 className="text-center mt-5">
                                    <i className="fa fa-info-circle mr-2"></i>
                                    {t('tickettypes.renewTypeInformation.one_time_pass_price_information')}
                                </h5>
                            }
                            {!isOneTimePass &&
                                <>
                                <br />
                                {tickettype?.pricings && showPricingsForm}
                                <Row>
                                    <Col className="text-center">
                                        <Button color="info" disabled={submitting || isLoading} onClick={_addPricing}>
                                            {isLoading ?
                                                <span>
                                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                </span>
                                                :
                                                <span>
                                                    <i className="fa fa-plus"></i>&nbsp;
                                                    {t("tickettypes.add_pricing")}
                                                </span>
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                                </>
                            }
                        </TabPane>
                    </TabContent>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="windows">
                            <br />
                            <WindowsForm windows={(tickettype || {}).windows} remove={_removeWindow} />
                            <Row>
                                <Col className="text-center">
                                    <Button color="info" disabled={submitting || isLoading} onClick={_addWindow}>
                                        {isLoading ?
                                            <span>
                                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                            </span>
                                            :
                                            <span>
                                                <i className="fa fa-plus"></i>&nbsp;
                                                {t("tickettypes.add_window")}
                                            </span>
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="fields">
                            <br />
                            {getAvailableSalepoints() && getAvailableSalepoints().length > 0 && (
                                <Row>
                                    {salepoints && (
                                        <Col>
                                            <InputGroup>
                                                <SalepointsField value={selectedSalepointIds} onChange={onSalepointsChange} name={'salepoint'} salepoints={getAvailableSalepoints()} fixedWidth />
                                                <Button className="btn_addFields" color="info" disabled={!selectedSalepointIds || isLoading} onClick={_addFields}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-plus"></i>&nbsp;
                                                            {t("tickettypes.add_fields")}
                                                        </span>
                                                    }
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    )}
                                </Row>
                            )}
                            <FieldsForm salepoints={salepoints} remove={_removeFields} />
                        </TabPane>
                    </TabContent>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="pdflayouts">
                            {Object.keys(tickettype).length > 0 &&
                                <Row>
                                    <Col>
                                        <InputGroup className='mt-4 w-50'>
                                            {tickettype._id === "one-time-pass" &&
                                            <>
                                            {pricinglists.length > 0 && (
                                                <Input type="select" onChange={e => changePricing(e.target.value)}>
                                                    <option value={null}>{ t('pricinglists.pricing.pricing_choice') }</option>
                                                    {pricinglists.map(list => (
                                                        Object.keys(list.pricings).length > 0 && (
                                                            <optgroup key={list._id} label={list.name} >
                                                                {Object.keys(list.pricings).map((key, index) => {
                                                                    const isHidden = tickettypeLayouts.includes(key);
                                                                    if (isHidden) return null;
                                                                    return (
                                                                        <option key={index} value={key}>
                                                                            {Utils.localized_or_fallback(list.pricings[key].name, i18n.language)} -
                                                                            {list.pricings[key].price[Utils.currency()]} {Utils.currency()}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </optgroup>
                                                        )
                                                    ))}
                                                </Input>
                                            )}
                                            </>
                                            }
                                            {tickettype._id != "one-time-pass" &&
                                                <PricingsField pricings={pricings_for_field || []} onChange={changeLayoutPricing} />
                                            }
                                            <InputGroupAddon addonType="append">
                                                <Button color="success" disabled={disabledBtnAddTemplate || isLoading} onClick={addLayoutPricing} >
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-plus"></i>&nbsp;
                                                            {t('pricinglists.pricing.pricing_add_layout')}
                                                        </span>
                                                    }
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            }
                            <PdfLayoutMappings name="opaque.layouts" type={PdfLayout.LAYOUT_TYPE_TICKET} />
                            {tickettype?.pricings && tickettypeLayouts?.length > 0 && tickettypeLayouts.map(t =>
                                <div className='mt-2' key={t}>
                                    <PdfLayoutMappings
                                        id={t}
                                        pricingName={tickettype.pricings[t]?.name[i18n.language] ?? ""}
                                        name={`opaque.layouts.overrides.${t}`}
                                        type={PdfLayout.LAYOUT_TYPE_TICKET}
                                        removeLayout={removePricingLayout}
                                    />
                                </div>
                            )}
                        </TabPane>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="emails">
                                <EmailLayoutMappings name="opaque.emails.default" />
                            </TabPane>
                        </TabContent>
                    </TabContent>
                </>
            )}
            <Row>
                <Col className="text-center">
                    <br />
                    <ButtonGroup>
                        <Button type="submit" color="success" disabled={submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
TickettypeForm = reduxForm({
    form: 'tickettypeForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: false
})(TickettypeForm)

// You have to connect() to any reducers that you wish to connect to yourself
TickettypeForm = connect(
    state => ({
        initialValues: state.tickettypes.tickettype, // pull initial values from tickettypes reducer
        tickettypes: state.tickettypes,
    }), {}
)(TickettypeForm)

export default TickettypeForm;
