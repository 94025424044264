// Redux Tickettypes module
// see https://github.com/erikras/ducks-modular-redux

import { Tickettypes, Utils } from '../../services/';
import _ from 'lodash';

const LOADING_TICKETTYPES                = 'kronos/tickettypes/LOADING_TICKETTYPES';
const LOADING_TICKETTYPES_SUCCESS        = 'kronos/tickettypes/LOADING_TICKETTYPES_SUCCESS';
const LOADING_TICKETTYPES_FAILURE        = 'kronos/tickettypes/LOADING_TICKETTYPES_FAILURE';
const LOADING_TICKETTYPE                 = 'kronos/tickettypes/LOADING_TICKETTYPE';
const LOADING_TICKETTYPE_SUCCESS         = 'kronos/tickettypes/LOADING_TICKETTYPE_SUCCESS';
const LOADING_TICKETTYPE_FAILURE         = 'kronos/tickettypes/LOADING_TICKETTYPE_FAILURE';
const UPDATING_TICKETTYPE                = 'kronos/tickettypes/UPDATING_TICKETTYPE';
const UPDATING_TICKETTYPE_SUCCESS        = 'kronos/tickettypes/UPDATING_TICKETTYPE_SUCCESS';
const UPDATING_TICKETTYPE_FAILURE        = 'kronos/tickettypes/UPDATING_TICKETTYPE_FAILURE';
const START_CREATING_TICKETTYPE          = 'kronos/tickettypes/START_CREATING_TICKETTYPE';
const CREATING_TICKETTYPE                = 'kronos/tickettypes/CREATING_TICKETTYPE';
const CREATING_TICKETTYPE_SUCCESS        = 'kronos/tickettypes/CREATING_TICKETTYPE_SUCCESS';
const CREATING_TICKETTYPE_FAILURE        = 'kronos/tickettypes/CREATING_TICKETTYPE_FAILURE';
const DELETING_TICKETTYPE                = 'kronos/tickettypes/DELETING_TICKETTYPE';
const DELETING_TICKETTYPE_SUCCESS        = 'kronos/tickettypes/DELETING_TICKETTYPE_SUCCESS';
const DELETING_TICKETTYPE_FAILURE        = 'kronos/tickettypes/DELETING_TICKETTYPE_FAILURE';
const ADDING_PRICING                     = 'kronos/tickettypes/ADDING_PRICING';
const REMOVING_PRICING                   = 'kronos/tickettypes/REMOVING_PRICING';
const UPDATING_PRICINGS_KEYS             = 'kronos/tickettypes/UPDATING_PRICINGS_KEYS';
const ADDING_WINDOW                      = 'kronos/tickettypes/ADDING_WINDOW';
const REMOVING_WINDOW                    = 'kronos/tickettypes/REMOVING_WINDOW';
const ADDING_FIELDS                      = 'kronos/tickettypes/ADDING_FIELDS';
const REMOVING_FIELDS                    = 'kronos/tickettypes/REMOVING_FIELDS';
const ADDING_LAYOUT                      = 'kronos/tickettypes/ADDING_LAYOUT';
const REMOVING_LAYOUT                    = 'kronos/tickettypes/REMOVING_LAYOUT';
const ADDING_EMAIL_LAYOUT                = 'kronos/tickettypes/ADDING_EMAIL_LAYOUT';
const REMOVING_EMAIL_LAYOUT              = 'kronos/tickettypes/REMOVING_EMAIL_LAYOUT';
const UPDATING_TICKETTYPE_PRICINGS       = 'kronos/tickettypes/UPDATING_TICKETTYPE_PRICINGS';
const UPDATING_TICKETTYPES_ORDER         = 'kronos/tickettypes/UPDATING_TICKETTYPES_ORDER';
const UPDATING_TICKETTYPES_ORDER_SUCCESS = 'kronos/tickettypes/UPDATING_TICKETTYPES_ORDER_SUCCESS';
const UPDATING_TICKETTYPES_ORDER_FAILURE = 'kronos/tickettypes/UPDATING_TICKETTYPES_ORDER_FAILURE';
const DUPLICATING_TICKETTYPE             = 'kronos/pricinglists/DUPLICATING_TICKETTYPE';
const DUPLICATING_TICKETTYPE_SUCCESS     = 'kronos/pricinglists/DUPLICATING_TICKETTYPE_SUCCESS';
const DUPLICATING_TICKETTYPE_FAILURE     = 'kronos/pricinglists/DUPLICATING_TICKETTYPE_FAILURE';
const LOADING_TICKETTYPES_REFS           = 'kronos/tickettypes/LOADING_TICKETTYPES_REFS';
const LOADING_TICKETTYPES_REFS_SUCCESS   = 'kronos/tickettypes/LOADING_TICKETTYPES_REFS_SUCCESS';
const LOADING_TICKETTYPES_REFS_FAILURE   = 'kronos/tickettypes/LOADING_TICKETTYPES_REFS_FAILURE';


const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    tickettypeSuccessfullyCreated: false,
    tickettypeSuccessfullyDeleted: false,
    duplicatedTickettype: {},
    tickettypes: [],
    tickettype: {},
    refs: [],
}

const initialStock = {
    salepoint_ids: [],
    availability: 0
};

const defaultFields = {
    "requested": [],
    "required": []
};

const initialPricing = (names, currency, vat, eshopWeight) => {
    return {
        name: names,
        price: { [currency]: 0 },
        value: { [currency]: 0 },
        value_per_screening: { [currency]: 0 },
        wallet_amount: { [currency]: 0 },
        VAT: vat || 0,
        category: "default",
        sellers: ['eshop', 'on-site', 'admin'],
        opaque: {
            eshop_sort_weight: eshopWeight
        }
    }
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_TICKETTYPES:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TICKETTYPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tickettypes: action.tickettypes,
                tickettypeSuccessfullyCreated: false,
                tickettypeSuccessfullyDeleted: false
            };
        case LOADING_TICKETTYPES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
                tickettypeSuccessfullyCreated: false,
                tickettypeSuccessfullyDeleted: false
            };
        case LOADING_TICKETTYPES_REFS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TICKETTYPES_REFS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                refs: action.refs,
            };
        case LOADING_TICKETTYPES_REFS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
            };
        case LOADING_TICKETTYPE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TICKETTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tickettype: action.tickettype
            };
        case LOADING_TICKETTYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_TICKETTYPE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_TICKETTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tickettype: action.tickettype
            };
        case UPDATING_TICKETTYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_TICKETTYPE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tickettypeSuccessfullyCreated: false,
                // Ensure sane default tickettype for creation
                tickettype: {
                    "name": "",
                    "pricings": {},
                    "opaque": {
                        "fields": {
                            ["default"]: defaultFields
                        }
                    }

                }
            };
        case CREATING_TICKETTYPE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_TICKETTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tickettypeSuccessfullyCreated: true,
                tickettype: action.tickettype
            };
        case CREATING_TICKETTYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                tickettypeSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_TICKETTYPE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_TICKETTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tickettypeSuccessfullyDeleted: true,
                tickettype: null
            };
        case DELETING_TICKETTYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                tickettypeSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case ADDING_PRICING:
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    pricings: {
                        ...state.tickettype.pricings,
                        [Date.now()]: initialPricing(action.names, action.currency, action.vat, Object.keys(state.tickettype.pricings).length + 1)
                    }
                }
            };
        case REMOVING_PRICING:
            const { [action._id]: toRemove, ...otherPricings } = state.tickettype.pricings;
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    pricings: otherPricings
                }
            };
        case UPDATING_PRICINGS_KEYS:
            let temp = _.cloneDeep(state.tickettype.pricings);
            if (temp[action.values.newId]) {
                return {
                    ...state
                }
            }
            else {
                temp[action.values.newId] = temp[action.values.oldId]
                delete temp[action.values.oldId];

                return {
                    ...state,
                    tickettype: {
                        ...state.tickettype,
                        pricings: temp
                    }
                }
            }
        case ADDING_WINDOW:
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    windows: (state.tickettype.windows || []).concat([{
                        nsame_screening: 1, // default value
                        nbookings: 1,       // default value
                        same_day: false     // default value
                    }])
                }
            };
        case REMOVING_WINDOW:
            const new_windows = _.cloneDeep(state.tickettype.windows);
            new_windows.splice(action.idx, 1);
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    windows: new_windows
                }
            };
        case ADDING_FIELDS:
            const fields = (
                ('fields' in state.tickettype.opaque) && (Object.keys(state.tickettype.opaque.fields).length > 0) ?
                    state.tickettype.opaque.fields : {}
            );
            fields[action.idx] = defaultFields;

            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    opaque: {
                        ...state.tickettype.opaque,
                        fields
                    }
                }
            };
        case REMOVING_FIELDS:
            const { [action.idx]: fieldToRemove, ...otherFields } = state.tickettype.opaque.fields;
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    opaque: {
                        ...state.tickettype.opaque,
                        fields: otherFields
                    }
                }
            };
        case ADDING_LAYOUT:
            state.tickettype.opaque = state.tickettype.opaque || {};
            state.tickettype.opaque.layouts = state.tickettype.opaque.layouts || {};
            state.tickettype.opaque.layouts.overrides = state.tickettype.opaque.layouts.overrides || {};
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    opaque: {
                        ...state.tickettype.opaque,
                        layouts: {
                            ...state.tickettype.opaque.layouts,
                            overrides: {
                                ...state.tickettype.opaque.layouts.overrides,
                                [action.idx] : {}
                            }
                        }
                    }
                }
            };
        case REMOVING_LAYOUT:
            let overrides = state.tickettype.opaque.layouts.overrides;
            delete overrides[action.idx];
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    opaque: {
                        ...state.tickettype.opaque,
                        layouts: {
                            ...state.tickettype.opaque.layouts,
                            overrides: overrides
                        }
                    }
                }
            };
        case ADDING_EMAIL_LAYOUT:
            state.tickettype.opaque = state.tickettype.opaque || {};
            state.tickettype.opaque.emails = state.tickettype.opaque.emails || {};
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    opaque: {
                        ...state.tickettype.opaque,
                        emails: {
                            ...state.tickettype.opaque.emails,
                            [action.idx]: {
                                'activation': null,
                                'creation': null,
                            }
                        }
                    }
                }
            };
        case REMOVING_EMAIL_LAYOUT:
            let emails = state.tickettype.opaque.emails;
            delete emails[action.idx];

            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    opaque: {
                        ...state.tickettype.opaque,
                        emails
                    }
                }
            };
        case UPDATING_TICKETTYPE_PRICINGS:
            return {
                ...state,
                tickettype: {
                    ...state.tickettype,
                    pricings: action.pricings
                }
            };
        case UPDATING_TICKETTYPES_ORDER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_TICKETTYPES_ORDER_SUCCESS:
            return {
                ...state,
                tickettypes: [
                    ...state.tickettypes
                ],
                isLoading: false,
                hasLoadingError: false,
                tickettype: action.tickettype
            };
        case UPDATING_TICKETTYPES_ORDER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case DUPLICATING_TICKETTYPE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
            };
        case DUPLICATING_TICKETTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                duplicatedTickettype: action.tickettype
            };
        case DUPLICATING_TICKETTYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingTickettypes() { return { type: LOADING_TICKETTYPES } }
function loadingTickettypesSuccess(tickettypes) { return { type: LOADING_TICKETTYPES_SUCCESS, tickettypes: tickettypes } }
function loadingTickettypesFailure(err) { return { type: LOADING_TICKETTYPES_FAILURE, error: err } }
export function loadTickettypes(tickettypename, password) {
    return (dispatch) => {
        dispatch(loadingTickettypes());
        Tickettypes.list()
            .then(data => {
                const tickettypes = data.tickettypes;
                dispatch(loadingTickettypesSuccess(tickettypes));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingTickettypesFailure(err))
            });
    }
}

function loadingTickettypesRefs() { return { type: LOADING_TICKETTYPES_REFS } }
function loadingTickettypesRefsSuccess(refs) { return { type: LOADING_TICKETTYPES_REFS_SUCCESS, refs } }
function loadingTickettypesRefsFailure(err) { return { type: LOADING_TICKETTYPES_REFS_FAILURE, error: err } }
export function loadTickettypesRefs() {
    return (dispatch) => {
        dispatch(loadingTickettypesRefs());
        Tickettypes.loadRefs()
            .then(data => {
                const refs = data.refs;
                dispatch(loadingTickettypesRefsSuccess(refs));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingTickettypesRefsFailure(err))
            });
    }
}

function loadingTickettype() { return { type: LOADING_TICKETTYPE } }
function loadingTickettypeSuccess(tickettype) { return { type: LOADING_TICKETTYPE_SUCCESS, tickettype: tickettype } }
function loadingTickettypeFailure(err) { return { type: LOADING_TICKETTYPE_FAILURE, error: err } }
export function loadTickettype(tickettypeId) {
    return (dispatch) => {
        dispatch(loadingTickettype());
        Tickettypes.get(tickettypeId)
            .then(data => {
                const tickettype = data.tickettype;
                dispatch(loadingTickettypeSuccess(tickettype));
            })
            .catch(err => {
                dispatch(loadingTickettypeFailure(err))
            });
    }
}

function updatingTickettype() { return { type: UPDATING_TICKETTYPE } }
function updatingTickettypeSuccess(tickettype) { return { type: UPDATING_TICKETTYPE_SUCCESS, tickettype: tickettype } }
function updatingTickettypeFailure(err) { return { type: UPDATING_TICKETTYPE_FAILURE, error: err } }
export function updateTickettype(tickettype) {
    return (dispatch) => {
        dispatch(updatingTickettype());
        Tickettypes.update(tickettype)
            .then(data => {
                const tickettype = data.tickettype;
                dispatch(updatingTickettypeSuccess(tickettype));
            })
            .catch(err => {
                dispatch(updatingTickettypeFailure(err))
            });
    }
}

function creatingTickettype() { return { type: CREATING_TICKETTYPE } }
function creatingTickettypeSuccess(tickettype) { return { type: CREATING_TICKETTYPE_SUCCESS, tickettype: tickettype } }
function creatingTickettypeFailure(err) { return { type: CREATING_TICKETTYPE_FAILURE, error: err } }
export function startCreateTickettype() { return { type: START_CREATING_TICKETTYPE } }
export function createTickettype(tickettype) {
    return (dispatch) => {
        dispatch(creatingTickettype());
        Tickettypes.create(tickettype)
            .then(data => {
                const tickettype = data.tickettype;
                dispatch(creatingTickettypeSuccess(tickettype));
                dispatch(loadingTickettypeSuccess(tickettype));
            })
            .catch(err => {
                dispatch(creatingTickettypeFailure(err))
            });
    }
}

function deletingTickettype() { return { type: DELETING_TICKETTYPE } }
function deletingTickettypeSuccess() { return { type: DELETING_TICKETTYPE_SUCCESS } }
function deletingTickettypeFailure(err) { return { type: DELETING_TICKETTYPE_FAILURE, error: err } }
export function deleteTickettype(tickettypeId) {
    return (dispatch) => {
        dispatch(deletingTickettype());
        Tickettypes.delete(tickettypeId)
            .then(data => {
                dispatch(deletingTickettypeSuccess());
            })
            .catch(err => {
                dispatch(deletingTickettypeFailure(err))
            });
    }
}

function addingPricing(names, currency) {
    const vat = Utils.tax()?.vat_tickets ?? 0;
    return { type: ADDING_PRICING, names, currency, vat }
}
export function addPricing(names, currency) {
    return (dispatch) => {
        dispatch(addingPricing(names, currency));
    }
}

function removingPricing(_id) { return { type: REMOVING_PRICING, _id } }
export function removePricing(_id) {
    return (dispatch) => {
        dispatch(removingPricing(_id));
    }
}

function updatingPricingsKeys(values) { return { type: UPDATING_PRICINGS_KEYS, values } }
export function updatePricingsKeys(values) {
    return (dispatch) => {
        dispatch(updatingPricingsKeys(values));
    }
}

function addingWindow() { return { type: ADDING_WINDOW } }
export function addWindow() {
    return (dispatch) => {
        dispatch(addingWindow());
    }
}

function removingWindow(idx) { return { type: REMOVING_WINDOW, idx } }
export function removeWindow(idx) {
    return (dispatch) => {
        dispatch(removingWindow(idx));
    }
}

function addingFields(idx) { return { type: ADDING_FIELDS, idx } }
export function addFields(idx) {
    return (dispatch) => {
        dispatch(addingFields(idx));
    }
}

function removingFields(idx) { return { type: REMOVING_FIELDS, idx } }
export function removeFields(idx) {
    return (dispatch) => {
        dispatch(removingFields(idx));
    }
}

function addingLayout(idx) { return { type: ADDING_LAYOUT, idx } }
export function addLayout(idx) {
    return (dispatch) => {
        dispatch(addingLayout(idx));
    }
}

function removingLayout(idx) { return { type: REMOVING_LAYOUT, idx } }
export function removeLayout(idx) {
    return (dispatch) => {
        dispatch(removingLayout(idx));
    }
}

function addingEmailLayout(idx) { return { type: ADDING_EMAIL_LAYOUT, idx } }
export function addEmailLayout(idx) {
    return (dispatch) => {
        dispatch(addingEmailLayout(idx));
    }
}

function removingEmailLayout(idx) { return { type: REMOVING_EMAIL_LAYOUT, idx } }
export function removeEmailLayout(idx) {
    return (dispatch) => {
        dispatch(removingEmailLayout(idx));
    }
}

function updatingTickettypePricings(pricings) { return { type: UPDATING_TICKETTYPE_PRICINGS, pricings } }
export function updateTickettypePricings(pricings) {
    return (dispatch) => {
        dispatch(updatingTickettypePricings(pricings));
    }
}

function duplicatingTickettype() { return { type: DUPLICATING_TICKETTYPE } }
function duplicatingTickettypeSuccess(tickettype) { return { type: DUPLICATING_TICKETTYPE_SUCCESS, tickettype: tickettype } }
function duplicatingTickettypeFailure(err) { return { type: DUPLICATING_TICKETTYPE_FAILURE, error: err } }
export function duplicateTickettype(tickettype, newId) {
    return (dispatch) => {
        dispatch(duplicatingTickettype());
        Tickettypes.duplicate(tickettype, newId)
            .then(data => {
                const pricinglist = data.tickettype;
                dispatch(duplicatingTickettypeSuccess(tickettype));
            })
            .catch(err => {
                dispatch(duplicatingTickettypeFailure(err))
            });
    }
}
