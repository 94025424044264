import BaseModel from './BaseModel';

/** Class representing a tkt instance. */
class TktInstance extends BaseModel {
    hasModule = (module) => {
        return this.options && this.options.modules &&
           this.options.modules.includes(module);
    }

    hasEventivalIntegration = (setting) => {
        const eventival = setting?.integrations?.eventival;
        return (eventival?.api_key?.length > 0 && eventival.name?.length > 0 && eventival.year?.length > 0);
    }

    hasJsonIntegration = (setting) => {
        return (
            !!setting?.integrations?.json?.import?.screenings?.enabled &&
            setting.integrations.json.places_key?.length > 0
        );
    }
}

export default TktInstance;
