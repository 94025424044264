import React, { useState, useEffect} from 'react';
import { Field } from 'redux-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label, Row, Col, Input, InputGroupAddon, Button, FormFeedback } from 'reactstrap';
import { TagsField, TranslatableField, MoneyField, PercentageField, RolesField, TickettypesField, WysiwygField, RefField } from '../../components';
import { Utils } from '../../services';
import classnames from 'classnames';
import HelpTooltips from '../HelpTooltips/HelpTooltips';
import AdditionnalClassesField from '../AdditionnalClassesField';
import './PricingForm.css';

const PricingForm = ({ pricing, pricingId, isLoading, changeId, pricingKeys, smallCard }) => {
    const { t }                     = useTranslation();
    const currency                  = Utils.currency();
    const [activeTab, setActiveTab] = useState('base');
    const { tickettypes }           = useSelector(state => state.tickettypes);
    const [id, setId]               = useState(pricingId);
    const [idValid, setIdValid]     = useState(true);
    const { user }                  = useSelector(state => state.auth);
    const { setting }               = useSelector(state => state.settings);
    const { refs }                  = useSelector(state => state.tickettypes);
    const { tags }                  = useSelector(state => state.tags);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const onChangeId = () => {
        changeId({newId: id, oldId: pricingId});
    }

    useEffect(() => {
        setIdValid(isValid());
    }, [id])

    const isValid = () => {
        if (!pricingKeys.find(k => k === id) || id === pricingId)
            return true;
        return false
    }

    const addTag = (tag) => {
        pricing.tags.push(tag);
        return pricing.tags;
    }

    return (
        <div className='PricingForm'>
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'base' })} onClick={() => { toggle('base'); }}>
                        {t('pricinglists.pricing.base_informations')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'display' })} onClick={() => { toggle('display'); }}>
                        {t('pricinglists.pricing.display')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'rules' })} onClick={() => { toggle('rules'); }}>
                        {t('pricinglists.pricing.rules')}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane className='border-bottom border-right border-left' tabId="base">
                    <br />
                    <FormGroup row >
                        <Label for="_id" className="text-v-center" sm={smallCard ? 2 : 12}>{t("tickettypes._id")}</Label>
                        <Col sm={smallCard ? 10 : 12}>
                            <InputGroupAddon addonType="append">
                                <Input type="text" valid={idValid} invalid={!idValid} className="form-control" required value={id} onChange={e => setId(e.target.value.replace(/[^a-zA-Z0-9_]/, ""))} component="input" />
                                <Button color={`${idValid ? 'success' : 'warning'}`} className="btn" disabled={!idValid} onClick={onChangeId}> {
                                    isLoading ?
                                        <span>
                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                        </span>
                                        :
                                        <span>
                                            <i className="fa fa-save"></i>
                                        </span>
                                }
                                </Button>
                                {!idValid &&
                                    <FormFeedback tooltip invalid="true">{t("tickettypes.id_already_used")}</FormFeedback>
                                }
                            </InputGroupAddon>
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label for="name" className="text-v-center" sm={smallCard ? 2 : 12}>{t("pricinglists.pricing.name")}</Label>
                        <Col sm={smallCard ? 10 : 12}>
                            <TranslatableField data-tip data-for={`nameTip.${pricingId}`} required name={`pricings[${pricingId}].name`} placeholder={t("pricinglists.pricing.name_placeholder")} component="input" />
                            <HelpTooltips id={`nameTip.${pricingId}`} value='name' />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label className="text-v-center" for="name" sm={smallCard ? 2 : 12}>{t("pricinglists.pricing.description")}</Label>
                        <Col sm={smallCard ? 10 : 12}>
                            <TranslatableField data-tip data-for={`descriptionTip.${pricingId}`} optional name={`pricings[${pricingId}].description`} placeholder={t("pricinglists.pricing.description_placeholder")} component={WysiwygField} />
                            <HelpTooltips id={`descriptionTip.${pricingId}`} value='description' />
                        </Col>
                    </FormGroup>
                    <hr />
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="price" sm={smallCard ? 4 : 12}>{t("pricinglists.pricing.price")}</Label>
                                <Col sm={smallCard ? 8 : 12} data-tip data-for={`priceTip.${pricingId}`}>
                                    <MoneyField required name={`pricings[${pricingId}].price[${currency}]`} />
                                    <HelpTooltips id={`priceTip.${pricingId}`} value='price' />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row>
                                <Label for="wallet_amount" sm={smallCard ? 4 : 12} className="text-right">{t("pricinglists.pricing.wallet_amount")}</Label>
                                <Col sm={smallCard ? 8 : 12}>
                                    <MoneyField data-tip data-for={`walletAmountTip.${pricingId}`} min={0} required name={`pricings[${pricingId}].wallet_amount[${currency}]`} />
                                    <HelpTooltips id={`walletAmountTip.${pricingId}`} value='wallet_amount' />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="value" sm={smallCard ? 4 : 12}>{t("pricinglists.pricing.value")}</Label>
                                <Col sm={smallCard ? 8 : 12}>
                                    <MoneyField data-tip data-for={`valueTip.${pricingId}`} required name={`pricings[${pricingId}].value[${currency}]`} />
                                    <HelpTooltips id={`valueTip.${pricingId}`} value='value' />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row>
                                <Label for="value_per_screening" sm={smallCard ? 4 : 12} className="text-right">{t("pricinglists.pricing.value_per_screening")}</Label>
                                <Col sm={smallCard ? 8 : 12}>
                                    <MoneyField data-tip data-for={`valuePerScreeningTip.${pricingId}`} required name={`pricings[${pricingId}].value_per_screening[${currency}]`} />
                                    <HelpTooltips id={`valuePerScreeningTip.${pricingId}`} value='value_per_screening' />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="VAT" sm={smallCard ? 4 : 12}>{t("pricinglists.pricing.VAT")}</Label>
                                <Col sm={smallCard ? 8 : 12}>
                                    <PercentageField required name={`pricings[${pricingId}].VAT`} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row>
                                <Label for="category" sm={smallCard ? 4 : 12} className="text-right">{t("pricinglists.pricing.category")}</Label>
                                <Col sm={smallCard ? 8 : 12}>
                                    <Field data-tip data-for={`categoryTip.${pricingId}`} component="input" type="text" className="form-control" name={`pricings[${pricingId}].category`} placeholder="default" />
                                    <HelpTooltips id={`categoryTip.${pricingId}`} value='category' />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <FormGroup row>
                        <Label className="text-v-center" for="name" sm={smallCard ? 2 : 12}>{t("pricinglists.pricing.eligibility")}</Label>
                        <Col sm={smallCard ? 10 : 12}>
                            <TranslatableField data-tip data-for={`eligibilityTip.${pricingId}`} optional name={`pricings[${pricingId}].opaque.eligibility`} placeholder={t("pricinglists.pricing.eligibility_placeholder")} component="input" />
                            <HelpTooltips id={`eligibilityTip.${pricingId}`} value='eligibility' />
                        </Col>
                    </FormGroup>
                    {(refs?.length > 0) && (
                        <>
                            <hr />
                            <FormGroup row>
                                <Label className="text-v-center" for="name" sm={smallCard ? 2 : 12}>{t("pricinglists.pricing.refs")}</Label>
                                <Col sm={smallCard ? 10 : 12}>
                                    <RefField data-tip data-for={`refsTip.${pricingId}`} name={`pricings[${pricingId}].refs`} refs={refs} />
                                    <HelpTooltips id={`refsTip.${pricingId}`} value='refs' />
                                </Col>
                            </FormGroup>
                        </>
                    )}

                    <hr />
                    <FormGroup row>
                        <Label className="text-v-center" for="name" sm={smallCard ? 2 : 12}>{t("pricinglists.pricing.tags")}</Label>
                        <Col sm={smallCard ? 10 : 12}>
                            <TagsField data-tip data-for={`tagsTip.${pricingId}`} name={`pricings[${pricingId}].tags`} tags={tags} addTag={addTag}  />
                            <HelpTooltips id={`tagsTip.${pricingId}`} value='tags' />
                        </Col>
                    </FormGroup>
                </TabPane>
                <TabPane tabId="display">
                    <br />
                    <FormGroup row>
                        <Label for="additional_class_names" sm={smallCard ? 2 : 12} className="text-right">{t("pricinglists.pricing.additional_class_names")}</Label>
                        <Col sm={smallCard ? 10 : 12}>
                            <AdditionnalClassesField data-tip data-for={`cssclassTip.${pricingId}`} name={`pricings[${pricingId}].opaque.additional_class_names`} />
                            <HelpTooltips id={`cssclassTip.${pricingId}`} value='css_class' />
                        </Col>
                    </FormGroup>
                </TabPane>
                <TabPane tabId="rules">
                    <br />
                    { /*
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="name" sm={12}>{t("pricinglists.pricing.not_before")}</Label>
                                <Col sm={12} data-tip data-for={`validefromTip.${pricingId}`}>
                                    <Field
                                        component={DateTimePicker}
                                        type="text"
                                        name={`pricings[${pricingId}].rules.not_before`}
                                        timeFormat={false}
                                    />
                                    <HelpTooltips id={`validefromTip.${pricingId}`} value='valid_from' />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            { tickettypes && (
                            <FormGroup row>
                                <Label for="name" sm={12}>{t("pricinglists.pricing.not_after")}</Label>
                                <Col sm={12} data-tip data-for={`validetoTip.${pricingId}`}>
                                    <Field
                                        component={DateTimePicker}
                                        type="text"
                                        name={`pricings[${pricingId}].rules.not_after`}
                                        timeFormat={false}
                                    />
                                    <HelpTooltips id={`validetoTip.${pricingId}`} value='valid_to' />
                                </Col>
                            </FormGroup>
                            )}
                        </Col>
                    </Row>
        */ }
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="name" sm={12}>{t("pricinglists.pricing.limit_to_sellers")}</Label>
                                <Col sm={12} data-tip data-for={`roleTip.${pricingId}`}>
                                    <RolesField name={`pricings[${pricingId}].sellers`} />
                                </Col>
                                <HelpTooltips id={`roleTip.${pricingId}`} value='role' />
                            </FormGroup>
                        </Col>
                    </Row>
                    {tickettypes && (
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label for="name" sm={12}>{t("pricinglists.pricing.limit_to_tickettypes")}</Label>
                                    <Col sm={12} data-tip data-for={`pricelimiteTip.${pricingId}`}>
                                        <TickettypesField
                                            name={`pricings[${pricingId}].rules.only_for_tickettypes`}
                                            tickettypes={tickettypes}
                                        />
                                    </Col>
                                    <HelpTooltips id={`pricelimiteTip.${pricingId}`} value='pricing_limit' />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Label for="name" sm={12}>{t("pricinglists.pricing.exclude_tickettypes")}</Label>
                                    <Col sm={12} data-tip data-for={`priceforbidenTip.${pricingId}`}>
                                        <TickettypesField
                                            name={`pricings[${pricingId}].rules.exclude_tickettypes`}
                                            tickettypes={tickettypes}
                                        />
                                    </Col>
                                    <HelpTooltips id={`priceforbidenTip.${pricingId}`} value='pricing_forbiden' />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                </TabPane>
            </TabContent>
        </div>
    );
}

export default PricingForm;

