import BaseModel from './BaseModel';

/** Class representing a task. */
class Task extends BaseModel {

    static STATUS_NEW       = 'new';
    static STATUS_FREE      = 'free';
    static STATUS_ASSIGNED  = 'assigned';
    static STATUS_CLAIMED   = 'claimed';
    static STATUS_ACCEPTED  = 'accepted';
    static STATUS_REFUSED   = 'refused';
    static STATUS_RENOUNCED = 'renounced';

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();

        delete prepared.has_started;
        delete prepared.is_assignable;
        delete prepared.is_assigned;
        delete prepared.is_claimable;
        delete prepared.is_claimed;
        delete prepared.is_editable;
        delete prepared.is_finished;
        delete prepared.is_renouncable;
        delete prepared.is_renounced;
        delete prepared.description;

        return prepared;
    }
}

export default Task;
