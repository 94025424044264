import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../components';
import { Button, ButtonGroup } from "reactstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Utils } from '../../../services';
import { updateEmailLayout } from '../../../redux';
import EmailLayouts from './EmailLayouts';

import './LayoutsList.css';

const LayoutsList = props => {
    const { t, i18n } = useTranslation();
    const dispatch    = useDispatch();

    const handle       = useFullScreenHandle();
    const { isMobile } = useSelector(state => state.ui);
    const { layout }   = useSelector(state => state.emailLayouts);

    const onSubmit = values => {
        dispatch(updateEmailLayout(values));
    };

    const actions = (
        <ButtonGroup className={!isMobile ? "pull-right" : "col-12 p-0"}>
            { !handle.active && (
                <Button  className={isMobile ? "col-3" : ""} onClick={ handle.enter } type="button" color="info">
                    <i className="fa fa-arrows-alt"></i>
                </Button>
            )}
            { handle.active && (
                <Button onClick={ handle.exit } type="button" color="danger">
                    <i className="fa fa-times"></i>
                </Button>
            )}
        </ButtonGroup>
    );

    let title = <span>{t('app.email_layouts')}</span>;
    if (layout)
        title = (
            <span>
                {t('app.email_layouts')}
                <small> |&nbsp;{ t('layouts.update_layout', { name: Utils.localized_or_fallback(layout.name, i18n.language) })} </small>
            </span>
        );

    return (
        <div className="LayoutsList">
            <FullScreen handle={ handle }>
                <PageTitle icon="file-envelope-o" title={ title } actions={actions} />
                <EmailLayouts onSubmit={ onSubmit } />
            </FullScreen>
        </div>
    );
}

export default LayoutsList;
