import React, { useEffect, useState } from 'react';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux'
import { addPricing, loadPricinglists, removePricing, updateScreeningPricings, updateScreeningProperty, updateScreeningPricingKey } from '../../../../redux';
import ScreeningFormGeneralField from '../ScreeningFormGeneralField/ScreeningFormGeneralField';
import { Utils } from '../../../../services';
import { useTranslation } from 'react-i18next';
import { PricingsForm } from '../../../../components';
import _ from 'lodash';

const ScreeningFormPricings = ({ pricings, isLoading }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { pricinglists } = useSelector(state => state.pricinglists);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        dispatch(loadPricinglists());
    }, []);

    const handlePricingsArray = (pricings) => {
        let result = {};
        pricings.map(p => {
            result[p.key] = p;
        })
        return result;
    }

    const updatePricingOrder = (pricings) => {
        const newPricings = handlePricingsArray(pricings);
        pricings.forEach((pricing, index) => {
            newPricings[pricing.key].opaque.eshop_sort_weight = index;
        });
        dispatch(updateScreeningPricings(newPricings));
    }

    const removeP = (id) => {
        dispatch(removePricing(id));
    };

    const _addPricing = () => {
        let names = {}
        Utils.langs().map(l => names[l] = t("pricinglists.new_pricing", { lng: l }))
        dispatch(addPricing(names, Utils.currency()));
    }

    const updatePricings = (values, field) => {
        let payload = { _id: values._id };
        payload[field] = values[field];
        dispatch(updateScreeningProperty(payload, field));
    }

    const applyPricings = (i) => {
        dispatch(updateScreeningPricings(pricinglists[i].pricings));
    }

    const changeId = (values) => {
        dispatch(updateScreeningPricingKey(values))
    }

    const showPricingsForm = React.useMemo(() => {
        return (
            <ScreeningFormGeneralField onSubmit={(values) => updatePricings(values, `pricings`)}>
                <PricingsForm
                    pricings={(pricings || {})}
                    remove={removeP}
                    updatePricingOrder={updatePricingOrder}
                    changeId={changeId}
                />
                <Row>
                    <Col className="text-center">
                        <ButtonGroup>
                            <Button color="info" disabled={isLoading} onClick={_addPricing}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-plus"></i>&nbsp;
                                        {t("pricinglists.add_pricing")}
                                    </span>
                                }
                            </Button>
                            <Button type="submit" color="success" disabled={isLoading}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-save"></i>&nbsp;
                                        {t("common.save")}
                                    </span>
                                }
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </ScreeningFormGeneralField>
        )
    }, [pricings]);


    return (
        <>
            {pricinglists.length > 0 &&
                <Row className="mb-3">
                    <Col>
                        <div className="float-right">
                            <ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                <DropdownToggle caret color="primary">Appliquer une grille tarifaire</DropdownToggle>
                                <DropdownMenu right>
                                    {pricinglists.map((p, i) => {
                                        return <DropdownItem key={i} onClick={() => applyPricings(i)}>{p.name}</DropdownItem>
                                    })}
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </Col>
                </Row>
            }
            {showPricingsForm}
        </>
    )
}

export default ScreeningFormPricings;
