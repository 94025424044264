import React from "react";
import PdfLayout from '../PdfLayout';
import PdfLayoutWidget from './PdfLayoutWidget';
import { Screen, Utils } from '../../services';
import { createStyleString } from '@capsizecss/core';

/** Class representing a PDF layout custom text widget. */
class CustomTextWidget extends PdfLayoutWidget {

    constructor(properties) {
        super({
            ...(CustomTextWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = PdfLayout.TEXT;
        this.subType = PdfLayout.CUSTOM;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        const textProperties = {};
        Utils.langs().forEach(lang => { textProperties[lang] = "";  });

        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 0.75 },
            transform: { rotation: 0 },
            options: {
                text: textProperties,
                style: {
                    color: '#000000',
                    fontFamily: "Helvetica",
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    textDecoration: 'none',
                    backgroundColor: '#FFFFFF00',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    textTransform: 'none'
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            PdfLayout.OPTION_COLOR,
            PdfLayout.OPTION_TEXT,
            PdfLayout.OPTION_FONT_FAMILY,
            PdfLayout.OPTION_FONT_SIZE,
            PdfLayout.OPTION_LINE_HEIGHT,
            PdfLayout.OPTION_TEXT_ALIGNMENT,
            PdfLayout.OPTION_PADDING,
            PdfLayout.OPTION_TEXT_DECORATION,
            PdfLayout.OPTION_TEXT_TRANSFORM
        ]);
    }

    /**
     * Return this widget preview text as string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const { inEdition, lang } = props;
        return this.options?.text[lang] || (inEdition ? '.....'  : '');
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     */
    getPreview(props) {
        const { fonts } = props;
        const font = (fonts || []).find(font => font.name === this.options?.style?.fontFamily);
        if (!font || !font.metrics)
            return null;

        const { metrics: { ascent, descent, unitsPerEm, capHeight, lineGap }} = font;

        const fontSize = Screen.ppToPx((this.options?.style?.fontSize || 12));
        const lineHeight = this.options?.style?.lineHeight || 1;

        const styles = createStyleString(this.id, {
            fontSize,
            leading: fontSize * lineHeight,
            fontMetrics: {
                capHeight: capHeight || 1000,
                ascent: ascent || 1000,
                descent: Math.abs(descent || 1000) * -1,
                unitsPerEm: unitsPerEm || 1000,
                lineGap: lineGap || 0
            },
        });

        return (
            <div style={{
                fontSize,
                lineHeight,
                width: Screen.cmToPx(this.dimensions?.width),
                height: Screen.cmToPx(this.dimensions?.height),
                color: this.options?.style?.color || '#000000',
                textAlign: this.options?.style?.textAlign,
                textTransform: this.options?.style?.textTransform,
                textDecoration: this.options?.style?.textDecoration,
                ...PdfLayout.pdfFontToCssStyle(this.options?.style?.fontFamily, this.options?.style?.fontFamilyVariant),
                backgroundColor: this.options?.style?.backgroundColor,
                border: `${this.options?.style?.borderWidth}px ${this.options?.style?.borderStyle} ${this.options?.style?.borderColor}`,
                borderRadius: `${this.options?.style?.borderRadius || 0}px`,
                paddingTop: `${Screen.cmToPx(this.options?.style?.padding?.top || 0)}px`,
                paddingRight: `${Screen.cmToPx(this.options?.style?.padding?.right || 0)}px`,
                paddingBottom: `${Screen.cmToPx(this.options?.style?.padding?.bottom || 0)}px`,
                paddingLeft: `${Screen.cmToPx(this.options?.style?.padding?.left || 0)}px`,
            }} >
                { props.interpretHtmlPreview && this.getPreviewText(props) }
                { !props.interpretHtmlPreview && (
                    <div className={this.id} dangerouslySetInnerHTML={{__html: this.getPreviewText(props) }} />
                )}
                <style type="text/css">{styles}</style>
            </div>
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'commenting-o';
    }
}

export default CustomTextWidget;
