import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, CardImg } from 'reactstrap';
import { submit } from 'redux-form';
import { loadPlaces, loadTickettypes } from '../../redux';
import ModalComponent from '../ModalComponent/ModalComponent';
import { Utils } from '../../services';

import ItemsList from '../ItemsList/ItemsList';
import FionaFields from './FionaFields/FionaFields';
import ComoFields from './ComoFields/ComoFields';
import OwnItFields from './OwnItFields/OwnItFields';
import ShiftFields from './ShiftFields/ShiftFields';
import CinechFields from './CinechFields/CinechFields';
import MaccsboxFields from './MaccsboxFields/MaccsboxFields';
import WordpressFields from './WordpressFields/WordpressFields';
import EventivalFields from './EventivalFields/EventivalFields';
import MailchimpFields from './MailchimpFields/MailchimpFields';
import PantaflixFields from './PantaflixFields/PantaflixFields';
import KronosImportFields from './KronosImportFields/KronosImportFields';
import WeservFields from './WeservFields/WeservFields';
import JsonImportFields from './JsonImportFields/JsonImportFields';
import GoogleRecaptchaFields from './GoogleRecaptchaFields/GoogleRecaptchaFields';
import GoogleTagManagerFields from './GoogleTagManagerFields/GoogleTagManagerFields';

import pantaflix from '../../assets/images/integrations/pantaflix.png';
import recaptcha from '../../assets/images/integrations/recaptcha.png';
import mailchimp from '../../assets/images/integrations/mailchimp.png';
import eventival from '../../assets/images/integrations/eventival.png';
import maccsbox from '../../assets/images/integrations/maccsbox.png';
import shift72 from '../../assets/images/integrations/shift72.png';
import cinech from '../../assets/images/integrations/cinech.png';
import kronos from '../../assets/images/integrations/kronos.png';
import weserv from '../../assets/images/integrations/weserv.png';
import fiona from '../../assets/images/integrations/fiona.png';
import own_it from '../../assets/images/integrations/own_it.png';
import json from '../../assets/images/integrations/json.png';
import como from '../../assets/images/integrations/como.png';
import gtm from '../../assets/images/integrations/gtm.png';
import wp from '../../assets/images/integrations/wp.png';

import './IntegrationsForm.css';

const IntegrationsForm = () => {
    const { t }            = useTranslation();
    const { user }         = useSelector(state => state.auth);
    const { places }       = useSelector(state => state.places);
    const { tickettypes }  = useSelector(state => state.tickettypes);
    const dispatch         = useDispatch();
    const { setting }      = useSelector(state => state.settings);
    const { integrations } = setting;
    const defaultSettings  = Utils.integrationParams;
    const disabledItems    = []

    const [activeIntegrationIndex, setActiveIntegrationIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    Object.keys(defaultSettings).filter(key => {
        if (key === 'weserv')
            return user.isSuperAdmin();

        return true;
    }).forEach(function (key) {
        if (!Object.keys(integrations).find(k => k === key))
            disabledItems.push(key);
    });

    useEffect(() => {
        dispatch(loadPlaces());
        dispatch(loadTickettypes());
    }, [dispatch])

    const integrationImage = integration => {
        switch (integration) {
            case 'pantaflix':
                return pantaflix;
            case 'recaptcha':
                return recaptcha;
            case 'mailchimp':
                return mailchimp;
            case 'eventival':
                return eventival;
            case 'maccsbox':
                return maccsbox;
            case 'shift72':
                return shift72;
            case 'cinech':
                return cinech;
            case 'kronos':
                return kronos;
            case 'weserv':
                return weserv;
            case 'fiona':
                return fiona;
            case 'own_it':
                return own_it;
            case 'json':
                return json;
            case 'como':
                return como;
            case 'gtm':
                return gtm;
            case 'wp':
                return wp;
        }
    };

    const enabledIntegrations = Object.keys(integrations).filter(key => {
        if (key === 'weserv')
            return user.isSuperAdmin();

        return true;
    }).map((key, i) => {

        return {
            key: i,
            selection: key,
            depth: 0,
            value: key,
            title: t('settings.integrations.' + key + '.title'),
            image: integrationImage(key),
            active: key === activeIntegrationIndex
        };
    })

    if (disabledItems.length > 0) {
        enabledIntegrations.push({
            key: '__add',
            selection: '__add',
            title: t("settings.integrations.add"),
            icon: 'plus',
            isButton: true,
            depth: 0
        });
    }

    const onSelect = index => {
        if (index == '__add') {
            setIsOpen(true)
            setActiveIntegrationIndex(0);
        } else {
            setActiveIntegrationIndex(index);
            setIsOpen(false)
        }
    };

    const onAddIntegration = (name) => {
        integrations[name] = defaultSettings[name]
        setActiveIntegrationIndex(name);
        setIsOpen(false);
        dispatch(submit('settingForm'));
    }

    const removeIntegration = () => {
        delete setting.integrations[activeIntegrationIndex];
        setActiveIntegrationIndex(Object.keys(setting.integrations)[0]);
    };

    return (
        <div className="IntegrationsForm">
            <div className="wrapper-0">
                <Row>
                    <Col lg={3}>
                        <ItemsList
                            title={t('settings.integrations.list')}
                            items={enabledIntegrations}
                            onSelect={onSelect}
                        />
                    </Col>
                    {activeIntegrationIndex != 0 &&
                        <Col lg={9}>
                            {
                                {
                                    'pantaflix': <PantaflixFields places={places} />,
                                    'recaptcha': <GoogleRecaptchaFields />,
                                    'mailchimp': <MailchimpFields />,
                                    'eventival': <EventivalFields />,
                                    'maccsbox' : <MaccsboxFields />,
                                    'shift72'  : <ShiftFields />,
                                    'cinech'   : <CinechFields />,
                                    'kronos'   : <KronosImportFields />,
                                    'weserv'   : <WeservFields />,
                                    'fiona'    : <FionaFields />,
                                    'own_it'   : <OwnItFields tickettypes={tickettypes} places={places} />,
                                    'como'     : <ComoFields />,
                                    'json'     : <JsonImportFields />,
                                    'gtm'      : <GoogleTagManagerFields />,
                                    'wp'       : <WordpressFields />,
                                }[activeIntegrationIndex]
                            }
                            <Button className='pull-right' color="danger" size="sm" onClick={removeIntegration}>
                                <i className="fa fa-trash"></i> {t('common.delete')}
                            </Button>
                        </Col>
                    }
                    {isOpen && disabledItems.length > 0 &&
                        <ModalComponent onClose={() => setIsOpen(false)} title={t('settings.integrations.list')}>
                            <div className="widget-chooser">
                                <div className="modal-choices">
                                    {disabledItems.map(name => (
                                        <div key={name} className="tab-choice" onClick={() => onAddIntegration(name)} >
                                            <p className="tab-choice-title">
                                                <img src={integrationImage(name)} className="img rounded" />
                                                {t(`settings.integrations.${name}.title`)}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </ModalComponent>
                    }
                </Row>
            </div>
        </div>
    );
}

export default IntegrationsForm;
