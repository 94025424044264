import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../components';
import { Utils } from '../../../services';
import { Button, ButtonGroup } from "reactstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { updateLayout, loadFonts, loadPricinglists, loadScreenings, loadProgrammations, loadPlaces, loadTickettypes } from "../../../redux";
import PdfLayouts from './PdfLayouts';

import './LayoutsList.css';

const LayoutsList = props => {
    const { t, i18n } = useTranslation();
    const dispatch    = useDispatch();

    const handle       = useFullScreenHandle();
    const { isMobile } = useSelector(state => state.ui);
    const { layout }   = useSelector(state => state.layouts);

    useEffect(() => {
        dispatch(loadScreenings({
            start_at_gte: null,
            stop_at_lte: null,
            places_ids: []
        }));
        dispatch(loadPricinglists());
        dispatch(loadProgrammations());
        dispatch(loadPlaces());
        dispatch(loadTickettypes());
        dispatch(loadFonts());
    }, [])

    const onSubmit = values => {
        dispatch(updateLayout(values));
    };

    const actions = (
        <ButtonGroup className={!isMobile ? "pull-right" : "col-12 p-0"}>
            { !handle.active && (
                <Button  className={isMobile ? "col-3" : ""} onClick={ handle.enter } type="button" color="info">
                    <i className="fa fa-arrows-alt"></i>
                </Button>
            )}
            { handle.active && (
                <Button onClick={ handle.exit } type="button" color="danger">
                    <i className="fa fa-times"></i>
                </Button>
            )}
        </ButtonGroup>
    );

    let title = <span>{t('app.layouts')}</span>;
    if (layout)
        title = (
            <span>
                {t('app.layouts')}
                <small> |&nbsp;{ t('layouts.update_layout', { name: Utils.localized_or_fallback(layout.name, i18n.language) })} </small>
            </span>
        );

    return (
        <div className="LayoutsList">
            <FullScreen handle={ handle }>
                <PageTitle icon="file-pdf-o" title={ title } actions={actions} />
                <PdfLayouts onSubmit={ onSubmit } />
            </FullScreen>
        </div>
    );
}

export default LayoutsList;
